/*eslint-env es6*/
import { Translations } from '@aws-amplify/ui-components';

const vocabularies = {
  ja: {
    [Translations.SIGN_IN_HEADER_TEXT]: 'ログインしてください。',
    [Translations.USERNAME_LABEL]: 'ユーザーID *',
    [Translations.PASSWORD_LABEL]: 'パスワード *',
    [Translations.USERNAME_PLACEHOLDER]: 'ユーザーIDを入力してください。',
    [Translations.PASSWORD_PLACEHOLDER]: 'パスワードを入力してください。',
    [Translations.FORGOT_PASSWORD_TEXT]: 'パスワードのリセット',
    [Translations.RESET_PASSWORD_TEXT]: 'パスワードのリセット',
    [Translations.NO_ACCOUNT_TEXT]: ' ',
    [Translations.SIGN_IN_ACTION]: 'ログイン',
    [Translations.BACK_TO_SIGN_IN]: 'ログインに戻る',
    [Translations.CHANGE_PASSWORD]: 'パスワードの変更',
    [Translations.NEW_PASSWORD_LABEL]: '新しいパスワード',
    [Translations.NEW_PASSWORD_PLACEHOLDER]:
      '新しいパスワードを入力してください。',
    [Translations.CHANGE_PASSWORD_ACTION]: '変更',
    [Translations.RESET_YOUR_PASSWORD]: 'パスワードのリセット',
    [Translations.SEND_CODE]: '認証コードの送信',
    [Translations.CODE_LABEL]: '認証コード',
    [Translations.CODE_PLACEHOLDER]: '認証コード入力してください。',
    [Translations.SUBMIT]: '実行',
    [Translations.DEFAULT_MSG]: 'エラー',
    'Password': 'パスワード',
    'Confirm Password': 'パスワード確認',
    'Confirm SMS Code': 'SMS認証コード',
    'Code': '認証コード',
    'New Password': '新しいパスワード',
    'Your passwords must match': 'パスワードは一致する必要があります。',
    'Username cannot be empty': 'ユーザー名を入力してください。',
    'Password cannot be empty': 'パスワードを入力してください。',
    "2 validation errors detected: Value at 'userAlias' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+; Value at 'userName' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+": 'ユーザー名またはパスワードが違います。',
    'User does not exist.': 'ユーザー名またはパスワードが違います。',
    'Incorrect username or password.': 'ユーザー名またはパスワードが違います。',
    'Username/client id combination not found.': 'ユーザーが見つかりません。',
    'User password cannot be reset in the current state.':
      'パスワードがリセットできません。',
    'Invalid verification code provided, please try again.':
      '認証コードが正しくありません。',
    'Password did not conform with policy: Password not long enough':
      'パスワードポリシーに適合していません。パスワードは8文字以上256文字以内で、英小文字、数字、記号それぞれを含めてください。',
    'Password did not conform to policy: Password not long enough':
      'パスワードポリシーに適合していません。パスワードは8文字以上256文字以内で、英小文字、数字、記号それぞれを含めてください。',
    'Password does not conform to policy: Password not long enough':
      'パスワードポリシーに適合していません。パスワードは8文字以上256文字以内で、英小文字、数字、記号それぞれを含めてください。',
    'Password did not conform with policy: Password must have numeric characters':
      'パスワードポリシーに適合していません。パスワードは8文字以上256文字以内で、英小文字、数字、記号それぞれを含めてください。',
    'Password does not conform to policy: Password must have numeric characters':
      'パスワードポリシーに適合していません。パスワードは8文字以上256文字以内で、英小文字、数字、記号それぞれを含めてください。',
    'Password did not conform with policy: Password must have symbol characters':
      'パスワードポリシーに適合していません。パスワードは8文字以上256文字以内で、英小文字、数字、記号それぞれを含めてください。',
    'Password does not conform to policy: Password must have symbol character':
      'パスワードポリシーに適合していません。パスワードは8文字以上256文字以内で、英小文字、数字、記号それぞれを含めてください。',
    'Password did not conform with policy: Password must have lowercase characters':
      'パスワードポリシーに適合していません。パスワードは8文字以上256文字以内で、英小文字、数字、記号それぞれを含めてください。',
    'Confirmation code cannot be empty': '認証コード入力してください。',
    "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length less than or equal to 256":
      'パスワードポリシーに適合していません。パスワードは8文字以上256文字以内で、英小文字、数字、記号それぞれを含めてください。',
    "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$":
      'パスワードポリシーに適合していません。パスワードは8文字以上256文字以内で、英小文字、数字、記号それぞれを含めてください。',
    "2 validation errors detected: Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$; Value at 'password' failed to satisfy constraint: Member must have length less than or equal to 256":
      'パスワードポリシーに適合していません。パスワードは8文字以上256文字以内で、英小文字、数字、記号それぞれを含めてください。',
    'Attempt limit exceeded, please try after some time.':
      '試行回数の制限を超えています。しばらくしてから試してください。',
    'Password attempts exceeded': 'パスワードの試行回数を超えました。',
    "1 validation error detected: Value at 'username' failed to satisfy constraint: Member must have length less than or equal to 128":
      'ユーザー名は128文字以内にしてください。',
    'Temporary password has expired and must be reset by an administrator.':
      '仮パスワードの有効期限が切れています。管理者に連絡してください。',
    'Cannot reset password for the user as there is no registered/verified email or phone_number':
      'メールアドレス、または電話番号が登録されていないため、パスワードをリセットできません。',
    'Create a new account': '新しいアカウントを作成する',
    'Create Account': 'アカウント作成',
    'Have an account?': 'アカウントを持っている。',
    'Email Address *': 'メールアドレス *',
    'Phone Number *': '電話番号 *',
    'Sign in': 'ログイン',
    'Confirm Sign up': 'サインアップの確認',
    'Confirmation Code': '確認コード',
    'Lost your code?': 'コードを紛失した？',
    'Enter your code': 'コードを入力',
    'Resend Code': 'コードを再送信',
    'Confirm': '確認',
    'Create account': 'アカウントを作成する',
  },
};

export default vocabularies;
