import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { SidebarService } from './sidebar.service';
import { filter, takeUntil } from 'rxjs/operators';
import { MatMenuTrigger } from '@angular/material/menu';
import { ModalService } from '../services/modal.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { AccessTokenService } from '../services/access-token.service';
import { AccountApiService } from '../services/account-api.service';
import { ProgressManagerService } from '../services/progress-manager.service';
import { isShowFormError } from '../utils/is-show-form-error';
import { Auth, Hub } from 'aws-amplify';
import { Me } from '../models/me';
import { SendCommonService } from '../services/send-common.service';
import { environment } from 'src/environments/environment';
import { CodeApiService } from '../services/code-api.service';
import { UserInfoService } from '../services/user-info.service';
import { AppsApiService } from 'src/app/services/apps-api.service';
import { MailApiService } from 'src/app/services/mail-api.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('slide', [
      state('up', style({ height: 0 })),
      state('down', style({ height: '*' })),
      transition('up <=> down', animate(200)),
    ]),
  ],
})
export class SidebarComponent implements OnInit, OnDestroy {
  @ViewChild(MatMenuTrigger) matMenuTrigger!: MatMenuTrigger;
  readonly isShowFormError = isShowFormError;
  registerForm: FormGroup | undefined;
  menus = [];
  showDetail = false;
  showSidebar = true;
  private onDestroy$ = new Subject<void>();
  progress$: Observable<boolean>;
  titleName = '';
  me: Me | undefined;
  userName = 'ユーザー名';
  orgName = '';
  userAuthorityName = '';
  authorityType: number | undefined = null;
  private subscriptionName: Subscription;

  constructor(
    public sidebarservice: SidebarService,
    private router: Router,
    private modalService: ModalService,
    private accessTokenService: AccessTokenService,
    private accountApiService: AccountApiService,
    private progressManagerService: ProgressManagerService,
    private sendCommonService: SendCommonService,
    private userInfoService: UserInfoService,
    private codeApiService: CodeApiService,
    private appsApiService: AppsApiService,
    private mailApiService: MailApiService
  ) {
    // ユーザー名更新
    this.subscriptionName = this.sendCommonService
      .getUserName()
      .subscribe((userName: any) => {
        if (userName?.name) {
          this.userName = userName.name;
        }
      });
    // @ts-ignore
    this.menus = sidebarservice.getMenuList();
    this.progress$ = this.progressManagerService.handler();
    // Hub.listen('auth', data => {
    //   if (data.payload.event === 'signIn') {
    //   }
    // });
  }
  ngOnDestroy(): void {
    if (this.subscriptionName) {
      this.subscriptionName.unsubscribe();
    }
  }

  ngOnInit() {
    this.getManualInfo();
    environment.authed = true;
    this.getCodes({ param: {} });
    this.getAppsList({ param: {} });
    this.getMails({ param: {} });
  }

  private getCodes(req) {
    this.codeApiService.getAdminCode(req).subscribe((res: any) => {
      const data = res?.api_data;
      this.userInfoService.setCommonCodes(JSON.stringify(data));
    });
  }

  private getAppsList(req) {
    this.appsApiService.getAppsList(req).subscribe((res: any) => {
      const data = res?.api_data;
      this.userInfoService.setCommonApps(JSON.stringify(data));
    });
  }

  private getMails(req) {
    this.mailApiService.getAdminMail(req).subscribe((res: any) => {
      const data = res?.api_data;
      this.userInfoService.setCommonMails(JSON.stringify(data));
    });
  }

  getSideBarState() {
    return this.sidebarservice.getSidebarState();
  }

  // @ts-ignore
  toggle(currentMenu) {
    if (currentMenu.type === 'dropdown') {
      this.menus.forEach(element => {
        if (element === currentMenu) {
          currentMenu.active = !currentMenu.active;
        } else {
          // @ts-ignore
          element.active = false;
        }
      });
    }
  }

  // @ts-ignore
  getState(currentMenu) {
    if (currentMenu.active) {
      return 'down';
    } else {
      return 'up';
    }
  }

  hasBackgroundImage() {
    return this.sidebarservice.hasBackgroundImage;
  }

  onAccountInfoPage() {
    this.router.navigate(['/me']);
  }

  onLogout() {
    this.modalService
      .showConfirm('確認', 'ログアウトします。よろしいですか。')
      .pipe(
        takeUntil(this.onDestroy$),
        filter(ok => ok)
      )
      .subscribe(async () => {
        // aws amplifyのsingoutを呼び出す。
        try {
          await Auth.signOut({ global: true });
        } catch (error) {
          await Auth.signOut();
        }
        this.userName = '';
        this.orgName = '';
        this.userAuthorityName = '';
        environment.authorityType = null;
        this.accessTokenService.clearToken();
        this.accountApiService.logout();
        this.userInfoService.setLoginInfo('');
        this.userInfoService.setSearchDevice('');
        this.userInfoService.setSearchOrganizations('');
        this.userInfoService.setSearchUsers('');
        this.userInfoService.setSearchCollection('');
        this.userInfoService.setSearchApprovals(
          JSON.stringify({
            param: {
              status_kbn: `'1201'`,
            },
          })
        );
        this.userInfoService.setSearchApplications(
          JSON.stringify({
            param: {
              status_kbn: `'1201','1202'`,
            },
          })
        );
        environment.authed = false;
        environment.authorityType = 0;
        this.router.navigate(['/login'], {
          queryParams: { kbn: environment.login_kbn },
        });
      });
  }
  openMyMenu() {
    this.matMenuTrigger.openMenu();
  }
  closeMyMenu() {
    this.matMenuTrigger.closeMenu();
  }

  private getManualInfo(callback: any = null) {
    this.accountApiService.me().subscribe((me: Me | undefined) => {
      this.me = me;
      if (
        this.me !== undefined &&
        this.me.first_name !== undefined &&
        this.me.family_name !== undefined
      ) {
        this.userInfoService.setLoginInfo(JSON.stringify(me));

        this.userName = this.me.family_name + ' ' + this.me.first_name;
        this.orgName = this.me.organizations_name ?? '';
        this.userAuthorityName = this.me.user_authority_kbn_name ?? '';

        const user_authority_kbn = this.me.user_authority_kbn;

        // メニュー初期化
        this.menus.forEach(e => {
          e.authority = [];
          if (e.submenus && e.submenus.length > 0) {
            e.submenus.forEach(m => {
              m.authority = [];
            });
          }
        });
        if (user_authority_kbn === '0101') {
          this.menus.forEach(e => {
            if (
              e.key !== undefined &&
              e.key !== null &&
              e.key === 'permission_level_application_selected_kbn'
            ) {
              e.authority.push('0201');
              if (e.submenus && e.submenus.length > 0) {
                e.submenus.forEach(m => {
                  m.authority.push('0201');
                });
              }
            }
          });
        } else if (user_authority_kbn === '0102') {
          this.menus.forEach(e => {
            if (
              e.key !== undefined &&
              e.key !== null &&
              e.key === 'permission_level_organization_manage_kbn'
            ) {
              e.authority.push('0201');
              if (e.submenus && e.submenus.length > 0) {
                e.submenus.forEach(m => {
                  m.authority.push('0201');
                });
              }
            }
          });
        } else if (user_authority_kbn === '0103') {
          this.menus.forEach(e => {
            if (
              (e.key !== undefined &&
                e.key !== null &&
                e.key === 'permission_level_user_manage_kbn') ||
              e.key === 'permission_level_organization_manage_kbn' ||
              e.key === 'permission_level_devices_manage_kbn' ||
              e.key === 'permission_level_approval_selected_kbn' ||
              e.key === 'permission_level_collection_manage_kbn'
            ) {
              e.authority.push('0201');
              if (e.submenus && e.submenus.length > 0) {
                e.submenus.forEach(m => {
                  m.authority.push('0201');
                });
              }
            }
          });
          this.router.navigate(['/apps'], {});
        }

        // 権限判定
        this.menus.forEach(e => {
          if (e.key !== undefined && e.key !== null) {
            e.authority.push(this.me[e.key]);
            if (e.submenus && e.submenus.length > 0) {
              e.submenus.forEach(m => {
                m.authority.push(this.me[e.key]);
              });
            }
          }
        });

        console.log('this.me, this.menus', this.me, this.menus);

        environment.authed = true;

        if (callback) {
          callback();
        }
      }
    });
  }
  getBlockedAuthType() {
    return '0201';
  }
  toggleSidebar() {
    this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());
  }
}
