import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AmplifyApiService } from './amplify-api.service';
import { ProgressManagerService } from './progress-manager.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UsersApiService {
  /** 結果処理用Subject */
  private resultSubject = new BehaviorSubject(undefined);

  /**
   * コンストラクタ
   */
  constructor(
    private progressManagerService: ProgressManagerService,
    private amplifyApiService: AmplifyApiService
  ) {}

  /**
   * ユーザー基本情報取得

   */
  postUsersOnestopMe(req) {
    this.progressManagerService.processing();
    return this.amplifyApiService
      .postWithAuth(this.resultSubject, '/users/onestop/me', req)
      .pipe(
        catchError((err: any): any => {
          return this.progressManagerService.done();
        })
      );
  }

  /**
   * 会員登録
   */
  postUsersRegister(req) {
    this.progressManagerService.processing();
    return this.amplifyApiService
      .postWithAuth(this.resultSubject, '/users/register', req)
      .pipe(
        catchError((err: any): any => {
          return this.progressManagerService.done();
        })
      );
  }

  /**
   * 会員登録
   */
  postUsersRegisterWithoutToken(req) {
    this.progressManagerService.processing();
    return this.amplifyApiService
      .postWithoutAuth(this.resultSubject, '/users/register', req)
      .pipe(
        catchError((err: any): any => {
          return this.progressManagerService.done();
        })
      );
  }

  /**
   * 会員退会実行
   */
  postUsersResign(req) {
    this.progressManagerService.processing();
    return this.amplifyApiService
      .postWithoutAuth(this.resultSubject, '/users/resign', req)
      .pipe(
        catchError((err: any): any => {
          return this.progressManagerService.done();
        })
      );
  }

  /**
   * 会員退会確認
   */
  postUsersResigncheck(req) {
    this.progressManagerService.processing();
    return this.amplifyApiService
      .postWithoutAuth(this.resultSubject, '/users/resigncheck', req)
      .pipe(
        catchError((err: any): any => {
          return this.progressManagerService.done();
        })
      );
  }

  /**
   * 組織一覧検索
   */
  getOrganizationsList(req) {
    this.progressManagerService.processing();
    return this.amplifyApiService
      .postWithAuth(this.resultSubject, '/users/organizations/search', req)
      .pipe(
        catchError((err: any): any => {
          return this.progressManagerService.done();
        })
      );
  }

  /**
   * 組織一覧検索
   */
  getOrganizationsListWithoutToken(req) {
    this.progressManagerService.processing();
    return this.amplifyApiService
      .postWithoutAuth(this.resultSubject, '/users/organizations/search', req)
      .pipe(
        catchError((err: any): any => {
          return this.progressManagerService.done();
        })
      );
  }

  /**
   * ユーザー一覧検索
   */
  postUsersUserlistsearch(req) {
    this.progressManagerService.processing();
    return this.amplifyApiService
      .postWithAuth(this.resultSubject, '/users/userlistsearch', req)
      .pipe(
        catchError((err: any): any => {
          return this.progressManagerService.done();
        })
      );
  }

  /**
   * ＡＷＳのパラメータストアーからValuesを取得
   */
  postUsersCommonAwsparametersGet(req) {
    this.progressManagerService.processing();
    return this.amplifyApiService
      .postWithoutAuth(
        this.resultSubject,
        '/users/common/awsparameters/get',
        req
      )
      .pipe(
        catchError((err: any): any => {
          return this.progressManagerService.done();
        })
      );
  }

  /**
   * ファイルアップロード
   */
  postUsersCommonFileupload(req) {
    this.progressManagerService.processing();
    return this.amplifyApiService
      .postWithAuth(this.resultSubject, '/users/common/fileupload', req)
      .pipe(
        catchError((err: any): any => {
          return this.progressManagerService.done();
        })
      );
  }

  /**
   * ファイルをアップロード
   */
  async fileUpload(file, key) {
    const partSize = 1024 * 1024 * 4;
    let start = 0;
    let end = Math.min(partSize, file.size);

    const allPartsNumber = Math.ceil(file.size / partSize);
    console.log('----アップロードするファイルの数-----', allPartsNumber);

    const promises = [];
    const currentDateTime = this.getCurrentDateTime();

    for (let partNumber = 1; start < file.size; partNumber++) {
      const blob = file.slice(start, end);

      const req = {
        param: {
          key: key,
          partNumber: partNumber,
          fileBody: await this.readBlobAsDataURL(blob),
          allPartsNumber: allPartsNumber,
          tempPath: currentDateTime,
        },
      };

      const promise = new Promise((resolve, reject) => {
        this.postUsersCommonFileupload(req).subscribe(res => {
          if (res) {
            console.log('No:', partNumber, 'ファイルのアップロード完了');
            resolve(res);
          } else {
            reject(new Error('アップロードに失敗しました'));
          }
        });
      });

      promises.push(promise);

      start = end;
      end = Math.min(start + partSize, file.size);
    }

    console.log('すべてのファイルのアップロードが完了しました');

    // すべての非同期操作が完了するのを待つ
    return await Promise.all(promises);
  }

  getCurrentDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}${month}${day}${hours}${minutes}${seconds}`;
  }

  async readBlobAsDataURL(blob) {
    let reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onload = function (event) {
        const fileData = reader.result as string;
        const bfFile = fileData.split(',')[1];
        resolve(bfFile);
      };

      reader.onerror = function (error) {
        reject(error);
      };

      try {
        reader.readAsDataURL(blob);
      } catch (error) {
        reject(error);
      }
    });
  }
}
