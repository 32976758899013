import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs/operators';

import {
  ConfirmDialogComponent,
  ConfirmDialogData,
} from '../modules/shared/confirm-dialog/confirm-dialog.component';
import {
  MessageDialogComponent,
  MessageDialogData,
} from '../modules/shared/message-dialog/message-dialog.component';
import { ServicePanelDialogComponent } from '../modules/shared/service-panel/service-panel-dialog.component';
import { ServicePanelData } from '../modules/shared/service-panel/service-panel.data';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private dialog: MatDialog) {}

  showConfirm(title: string, message: string, labelOk = '', labelCancel = '') {
    const dialogRef = this.dialog.open<
      ConfirmDialogComponent,
      ConfirmDialogData,
      boolean
    >(ConfirmDialogComponent, {
      width: '320px',
      height: '185px',
      data: {
        title,
        message,
        labelOk,
        labelCancel,
      },
    });
    return dialogRef.afterClosed().pipe(map(ok => !!ok));
  }

  showMessage(title: string, message: string) {
    const dialogRef = this.dialog.open<
      MessageDialogComponent,
      MessageDialogData,
      undefined
    >(MessageDialogComponent, {
      data: {
        title,
        message,
      },
    });
    return dialogRef.afterClosed();
  }

  showServicePanel(params: ServicePanelData) {
    const dialogRef = this.dialog.open(ServicePanelDialogComponent, {
      width: '1150px',
      height: '800px',
      data: {
        params,
      },
    });
    return dialogRef.afterClosed();
  }
}
