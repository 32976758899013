import { BrowserModule } from '@angular/platform-browser';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { Amplify, I18n } from 'aws-amplify';
import localeDe from '@angular/common/locales/ja';
import localeDeExtra from '@angular/common/locales/extra/ja';
import { MaterialModule } from './modules/material/material.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import {
  BsDatepickerModule,
  BsDatepickerConfig,
} from 'ngx-bootstrap/datepicker';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { DataTablesModule } from 'angular-datatables';
import { registerLocaleData } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { SharedModule } from './modules/shared/shared.module';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { MenuComponent } from './pages/menu/menu.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { UserResignResultComponent } from './pages/users/user-resign-result/user-resign-result.component';
// import { environment } from '../environments/environment';

// Amplify.configure({
//   // OPTIONAL - if your API requires authentication
//   Auth: {
//     // REQUIRED - Amazon Cognito Identity Pool ID
//     identityPoolId: environment.identityPoolId,
//     // REQUIRED - Amazon Cognito Region
//     region: environment.region,
//     // OPTIONAL - Amazon Cognito User Pool ID
//     userPoolId: environment.userPoolId,
//     // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
//     userPoolWebClientId: environment.clientId,
//   },
//   API: {
//     endpoints: [
//       {
//         name: environment.apiName,
//         endpoint: environment.apiUrl,
//         region: environment.region,
//       },
//     ],
//   },
// });

registerLocaleData(localeDe, 'ja', localeDeExtra);
I18n.setLanguage('ja');

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

/**
 * アプリ全体を統括するモジュール
 */
@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    HomeComponent,
    LoginComponent,
    MenuComponent,
    UserResignResultComponent,
  ],
  imports: [
    BrowserModule,
    NgbModule,
    DataTablesModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    AmplifyAuthenticatorModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    AppRoutingModule,
    BsDropdownModule.forRoot(),
    PerfectScrollbarModule,
    ProgressbarModule,
    AccordionModule.forRoot(),
    BsDatepickerModule.forRoot(),
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatRadioModule,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    { provide: LOCALE_ID, useValue: 'ja' },
    BsDatepickerConfig,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
