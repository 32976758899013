import { Injectable } from '@angular/core';
import { BehaviorSubject, from, switchMap } from 'rxjs';
import { ProgressManagerService } from './progress-manager.service';
import { AmplifyApiService } from './amplify-api.service';
import { tap, share, filter, map } from 'rxjs/operators';
import Auth from '@aws-amplify/auth';

@Injectable({
  providedIn: 'root',
})
export class CodeApiService {
  private resultSubject = new BehaviorSubject(undefined);
  public codes = null;

  constructor(
    private progressManagerService: ProgressManagerService,
    private amplifyApiService: AmplifyApiService
  ) {}

  getAdminCode(req) {
    this.progressManagerService.processing();

    return from(
      Auth.currentSession()
        .then(data => data.getIdToken().getJwtToken())
        .catch(err => {
          this.progressManagerService.done();
          return '';
        })
    ).pipe(
      filter(token => !!token),
      switchMap(token => {
        return this.amplifyApiService.get('/admin/code', token, req).pipe(
          tap(res => {
            this.progressManagerService.done();
          }),
          share(),
          map(result => {
            console.log('result', result);
            this.codes = result;
            return result;
          }),
          tap(result => this.resultSubject.next(result))
        );
      })
    );
  }
}
