import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  toggled = false;
  _hasBackgroundImage = true;
  menus = [
    {
      title: 'ユーザー管理',
      icon: '',
      active: false,
      type: 'dropdown',
      authority: [],
      key: 'permission_level_user_manage_kbn',
      submenus: [
        {
          title: 'ユーザー一覧',
          url: '/users/list',
          authority: [],
          key: 'permission_level_user_manage_kbn',
        },
      ],
    }
  ];
  constructor() {}

  toggle(): void {
    this.toggled = !this.toggled;
  }

  getSidebarState(): boolean {
    return this.toggled;
  }

  setSidebarState(state: boolean): void {
    this.toggled = state;
  }

  getMenuList(): any {
    return this.menus;
  }

  get hasBackgroundImage(): any {
    return this._hasBackgroundImage;
  }

  set hasBackgroundImage(hasBackgroundImage) {
    this._hasBackgroundImage = hasBackgroundImage;
  }
}
