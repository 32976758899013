import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { MenuComponent } from './pages/menu/menu.component';

const routes: Routes = [
  {
    path: '',
    component: MenuComponent,
    children: [
      {
        path: 'sso',
        component: LoginComponent,
      },
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./pages/users/users.module').then(m => m.UsersModule),
      },
      {
        path: 'selection',
        loadChildren: () =>
          import('./pages/selection/selection.module').then(
            m => m.SelectionModule
          ),
      },
      {
        path: 'concierge',
        loadChildren: () =>
          import('./pages/concierge/concierge.module').then(
            m => m.ConciergeModule
          ),
      },
      {
        path: 'marutto',
        loadChildren: () =>
          import('./pages/maruto/maruto.module').then(m => m.MarutoModule),
      },
      {
        path: 'consultationinquiry',
        loadChildren: () =>
          import('./pages/consultationinquiry/consultationinquiry.module').then(
            m => m.ConsultationinquiryModule
          ),
      },
      {
        path: 'top',
        component: HomeComponent,
      },
      {
        path: '**',
        redirectTo: 'login',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
