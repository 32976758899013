import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {
  transform(value: any) {
    console.log('value', value);
    return `${value.substring(0, 3)}-${value.substring(3, 7)}-${value.substring(
      7,
      11
    )}`;
  }
}
