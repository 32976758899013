import {
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
} from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { filter, takeUntil } from 'rxjs/operators';
import { MatMenuTrigger } from '@angular/material/menu';
import { ModalService } from '../../services/modal.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { Router, NavigationStart } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { AccessTokenService } from '../../services/access-token.service';
import { AccountApiService } from '../../services/account-api.service';
import { ProgressManagerService } from '../../services/progress-manager.service';
import { isShowFormError } from '../../utils/is-show-form-error';
import { Auth } from 'aws-amplify';
import { Me } from '../../models/me';
import { environment } from 'src/environments/environment';
import { UserInfoService } from '../../services/user-info.service';
import { AdminApiService } from 'src/app/services/admin-api.service';
import { UsersApiService } from 'src/app/services/users-api.service';
import { SearchInfoService } from 'src/app/services/search-info.service';
import { formatMsg } from 'src/app/utils/common';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger('slide', [
      state('up', style({ height: 0 })),
      state('down', style({ height: '*' })),
      transition('up <=> down', animate(200)),
    ]),
  ],
})
export class MenuComponent implements OnInit, OnDestroy {
  @ViewChild(MatMenuTrigger) matMenuTrigger!: QueryList<MatMenuTrigger>;
  @ViewChild('menuBtn1', { read: MatMenuTrigger, static: false })
  menu1: MatMenuTrigger;
  @ViewChild('menuBtn2', { read: MatMenuTrigger, static: false })
  menu2: MatMenuTrigger;
  @ViewChild('menuBtn3', { read: MatMenuTrigger, static: false })
  menu3: MatMenuTrigger;
  @ViewChild('menuBtn4', { read: MatMenuTrigger, static: false })
  menu4: MatMenuTrigger;
  readonly isShowFormError = isShowFormError;
  registerForm: FormGroup | undefined;
  showSubMenu = false;
  menus = [];
  private onDestroy$ = new Subject<void>();
  progress$: Observable<boolean>;
  me: Me | undefined;
  showMenu = true;
  private subscriptionName: Subscription;
  shouWAccent = true;

  constructor(
    private router: Router,
    private modalService: ModalService,
    private accessTokenService: AccessTokenService,
    private accountApiService: AccountApiService,
    private progressManagerService: ProgressManagerService,
    private userInfoService: UserInfoService,
    private usersApiService: UsersApiService,
    private adminApiService: AdminApiService,
    private searchInfoService: SearchInfoService
  ) {
    this.progress$ = this.progressManagerService.handler();
  }
  ngOnDestroy(): void {
    if (this.subscriptionName) {
      this.subscriptionName.unsubscribe();
    }
  }

  ngOnInit() {
    console.log('------------ this.router.url------------', this.router.url);

    this.router.events.subscribe(event => {
      if (
        event instanceof NavigationStart &&
        (this.router.url.startsWith('/users/registerUser') ||
          this.router.url.startsWith('/users/registerResign') ||
          this.router.url.startsWith('/users/userResignResult'))
      ) {
        this.shouWAccent = false;
      }
    });

    if (
      this.router.url.startsWith('/users/registerUser') ||
      this.router.url.startsWith('/users/registerResign') ||
      this.router.url.startsWith('/users/userResignResult') ||
      this.router.url.startsWith('/users/maintenance')
    ) {
      environment.showMenu = false;
      return;
    }
    this.shouWAccent = true;
    environment.showMenu = true;
    // 退避項目の初期化
    this.initSearchInfo();

    //1.2　コード取得
    this.getAdminCode();

    //1.3　ユーザー基本情報取得
    this.getUsersOnestopMe();

    //輸送状況の外部URL
    this.setUrlTransportStatus();

    //購買システムURL
    this.setPurchaseSystemRrl();

    //
    this.defaultImgPath();

    environment.authed = true;
  }
  setUrlTransportStatus() {
    const req = {
      param: {
        paramter_names: 'url_transport_status',
      },
    };

    this.usersApiService.postUsersCommonAwsparametersGet(req).subscribe(res => {
      if (res) {
        sessionStorage.setItem('url_transport_status', res.parameterValues);
      }
    });
  }
  defaultImgPath() {
    const paramter_name = [
      'public/concierge_serviceImage',
      'public/concierge_companyLogo',
      'public/marutto_serviceImage',
      'public/marutto_companyLogo',
    ];
    const req = {
      param: {
        paramter_names: paramter_name.join(','),
        shori_kbn: '1',
      },
    };
    this.usersApiService.postUsersCommonAwsparametersGet(req).subscribe(res => {
      if (res) {
        for (var item of res.parameterValues) {
          for (var paramter of paramter_name) {
            if (item.key == paramter) {
              sessionStorage.setItem(item.key.substring(7), item.value);
              break;
            }
          }
        }
      }
    });
  }

  // 退避項目の初期化
  initSearchInfo() {
    this.router.events.subscribe(event => {
      if (
        event instanceof NavigationStart &&
        event.url.includes('fromMenu=1')
      ) {
        this.searchInfoService.clear();
      }
    });
  }

  async setPurchaseSystemRrl() {
    const req = {
      param: {
        paramter_names: 'purchase_system_url',
      },
    };

    this.usersApiService.postUsersCommonAwsparametersGet(req).subscribe(res => {
      if (res) {
        sessionStorage.setItem('purchase_system_url', res.parameterValues);
      }
    });
  }

  getAdminCode() {
    const req = {
      param: {
        code_uid: '',
      },
    };

    this.adminApiService.getAdminCode(req).subscribe((res: any) => {
      sessionStorage.setItem('codeInfo', JSON.stringify(res?.api_data));
    });
  }

  async getUsersOnestopMe() {
    //API実行
    this.accountApiService
      .me()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((me: Me | undefined) => {
        this.me = me;

        this.showInitPage();
      });
  }

  /**
   * 組織一覧検索
   */
  getorganizationInfo(): void {
    // 1.2.1 組織一覧検索
    const req = {
      param: {
        one_stop_flg: true,
      },
    };

    // 組織名（ドロップダウンリスト）作成
    this.usersApiService.getOrganizationsList(req).subscribe((res: any) => {
      const organizationsNames = res?.organizations_response_array;
      sessionStorage.setItem(
        'organizationsNames',
        JSON.stringify(organizationsNames)
      );
    });
  }

  private showInitPage() {
    this.showSubMenu = false;

    //1.1　（セッションストレージのユーザー基本情報.ユーザー権限≠"0101"（システム運用管理者））、　かつ
    //1.1　セッションストレージのユーザー基本情報.ワンストップフラグがFALSE（利用しない）、または
    //セッションストレージのユーザー基本情報.会員種別＝"1505"（一般会員）の場合、
    //ワンストップサービスの利用権限なしのポップアップを表示する。
    //メインエリアは空白で表示。
    if (!this.me) {
      return;
    }

    if (
      this.me.user_authority_kbn != '0101' &&
      (!this.me.one_stop_flg || this.me.user_kbn == '1505')
    ) {
      environment.showMenu = false;
      this.modalService
        .showMessage('', formatMsg('I0013', []))
        .pipe(
          //ワンストップサービスの利用権限なしのポップアップを表示する。
          takeUntil(this.onDestroy$),
          filter(ok => ok)
        )
        .subscribe(async () => {
          this.doLogout();
        });
      return;
    }

    // 1.2.1 メニューエリア設定
    //システム運用管理者の場合、サブメニュー表示
    if (this.me.user_authority_kbn == '0101') {
      this.showSubMenu = true;

      //組織一覧 sessionStorageに設定
      this.getorganizationInfo();
    } else {
      this.showSubMenu = false;
    }

    if (this.router.url.startsWith('/login')) {
      this.router.navigate(['/top']);
      return;
    }
  }

  private async doLogout() {
    debugger;
    // aws amplifyのsingoutを呼び出す。
    try {
      await Auth.signOut({ global: true });
    } catch (error) {
      await Auth.signOut();
    }

    sessionStorage.setItem('codeInfo', '');
    sessionStorage.setItem('meInfo', '');

    environment.authorityType = null;
    this.accessTokenService.clearToken();
    this.accountApiService.logout();
    this.searchInfoService.clear();
    this.userInfoService.setLoginInfo('');
    this.userInfoService.setSearchDevice('');
    this.userInfoService.setSearchOrganizations('');
    this.userInfoService.setSearchUsers('');
    this.userInfoService.setSearchCollection('');
    this.userInfoService.setSearchApprovals(
      JSON.stringify({
        param: {
          status_kbn: `'1201'`,
        },
      })
    );
    this.userInfoService.setSearchApplications(
      JSON.stringify({
        param: {
          status_kbn: `'1201','1202'`,
        },
      })
    );
    environment.authed = false;
    environment.authorityType = 0;
    this.router.navigate(['/login'], {
      queryParams: { kbn: environment.login_kbn },
    });
  }

  // @ts-ignore
  toggle(currentMenu) {
    if (currentMenu.type === 'dropdown') {
      this.menus.forEach(element => {
        if (element === currentMenu) {
          currentMenu.active = !currentMenu.active;
        } else {
          // @ts-ignore
          element.active = false;
        }
      });
    }
  }

  // @ts-ignore
  getState(currentMenu) {
    if (currentMenu.active) {
      return 'down';
    } else {
      return 'up';
    }
  }

  onAccountInfoPage() {
    this.router.navigate(['/me']);
  }

  onLogout() {
    this.modalService
      .showConfirm('確認', 'ログアウトします。よろしいですか。')
      .pipe(
        takeUntil(this.onDestroy$),
        filter(ok => ok)
      )
      .subscribe(async () => {
        this.doLogout();
      });
  }

  // 「アカウント情報」をクリック、「アカウント情報」画面へ遷移する
  onAccount() {
    this.router.navigate(['/users/userInfo']);
  }
  openMyMenu(btn: string) {
    switch (btn) {
      case 'menu1':
        this.menu1.openMenu();
        break;
      case 'menu2':
        this.menu2.openMenu();
        break;
      case 'menu3':
        this.menu3.openMenu();
        break;
      case 'menu4':
        this.menu4.openMenu();
        break;
    }
  }
  closeMyMenu(btn: string) {
    this.menu1.closeMenu();
    this.menu2.closeMenu();
    this.menu3.closeMenu();
    this.menu4.closeMenu();
  }
  getShowMenu() {
    return environment.showMenu;
  }
}
