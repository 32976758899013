import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SendCommonService {
    private subjectUserName = new Subject<any>();
    private subjectStatus = new Subject<any>();
    private subjectUpdate = new Subject<any>();
    private subjectReportUidArray = new Subject<any>();
    private subjectSpecificHealthGuidanceType = new Subject<any>();

    sendUserName(userName: string) {
        this.subjectUserName.next({ name: userName });
    }

    getUserName(): Observable<any> {
        return this.subjectUserName.asObservable();
    }

    sendStatus(status: number) {
        this.subjectStatus.next({ status: status });
    }

    getStatus(): Observable<any> {
        return this.subjectStatus.asObservable();
    }

    sendUpdate(update: boolean) {
        this.subjectUpdate.next({ update: update });
    }

    getUpdate(): Observable<any> {
        return this.subjectUpdate.asObservable();
    }

    sendReportUidArray(array: Array<any>) {
        this.subjectReportUidArray.next({ reportUidArray: array });
    }

    getReportUidArray(): Observable<any> {
        return this.subjectReportUidArray.asObservable();
    }
    sendSpecificHealthGuidanceType(specificHealthGuidanceType: number) {
        this.subjectSpecificHealthGuidanceType.next({ specificHealthGuidanceType: specificHealthGuidanceType });
    }

    getSpecificHealthGuidanceType(): Observable<any> {
        return this.subjectSpecificHealthGuidanceType.asObservable();
    }
}