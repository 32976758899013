import {
  Component,
  OnInit,
  ViewEncapsulation,
  HostBinding,
  Input
} from '@angular/core';

/**
 * 入力項目フィールドをゆるくラップするコンポーネント
 */
@Component({
  selector: 'app-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormFieldComponent implements OnInit {

  /**
   * ベーススタイルの css に対応した class を付与する
   */
  @HostBinding('class.c-input-field') class = true;

  /**
   * true / false で disabled を示す class を付け外しする
   */
  @Input()
  @HostBinding('class.c-input-field--disabled') disabled = false;

  /**
   * true / false で error を示す class を付け外しする
   */
  @Input()
  @HostBinding('class.c-input-field--error') error = false;

  constructor() {}

  ngOnInit() {}
}
