<nav
  id="sidebar"
  class="sidebar"
  [ngClass]="{ 'page-wrapper__sidebar__hidden': getSideBarState() }">
  <div class="sidebar-content">
    <perfect-scrollbar>
      <div class="sidebar-header">
        <h2>再生医療情報基盤<br />ポータル</h2>
      </div>
      <div class="sidebar-header-version">
        <span class="version-info">
          <strong>ver.1.0</strong>
        </span>
      </div>
      <div class="sidebar-menu" *ngIf="showSidebar">
        <ul>
          <li
            *ngFor="let menu of menus"
            [ngClass]="{
              active: menu.active,
              'sidebar-dropdown': menu.type === 'dropdown',
              'header-menu': menu.type === 'header'
            }">
            <span *ngIf="menu.type === 'header'">{{ menu.title }}</span>
            <a
              *ngIf="
                menu.type !== 'header' &&
                menu.type !== 'dropdown' &&
                !menu.authority.includes(getBlockedAuthType())
              "
              (click)="toggle(menu)"
              routerLink="{{ menu.url }}">
              <i class="{{ menu.icon }}" aria-hidden="true"></i>
              <span>{{ menu.title }}</span>
              <span
                *ngIf="menu.badge"
                class="badge badge-pill"
                [ngClass]="menu.badge.class"
                >{{ menu.badge.text }}</span
              >
            </a>
            <a
              *ngIf="
                menu.type === 'dropdown' &&
                !menu.authority.includes(getBlockedAuthType())
              "
              (click)="toggle(menu)">
              <i class="{{ menu.icon }}" aria-hidden="true"></i>
              <span>{{ menu.title }}</span>
              <span
                *ngIf="menu.badge"
                class="badge badge-pill"
                [ngClass]="menu.badge.class"
                >{{ menu.badge.text }}</span
              >
            </a>
            <div
              *ngIf="
                menu.type === 'dropdown' &&
                !menu.authority.includes(getBlockedAuthType())
              "
              class="sidebar-submenu"
              [@slide]="getState(menu)">
              <ul>
                <li *ngFor="let submenu of menu.submenus">
                  <a
                    *ngIf="!submenu.authority.includes(getBlockedAuthType())"
                    routerLink="{{ submenu.url }}">
                    {{ submenu.title }}
                    <span
                      *ngIf="submenu.badge"
                      class="badge badge-pill"
                      [ngClass]="submenu.badge.class"
                      >{{ submenu.badge.text }}</span
                    >
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div
        class="sidebar-arrow"
        [ngClass]="{ siderbar__conunts__hidden: getSideBarState() }"
        (click)="toggleSidebar()"
        *ngIf="showSidebar">
        <i class="fa fa-angle-double-left" aria-hidden="true"></i>
      </div>
    </perfect-scrollbar>
  </div>
</nav>
<nav
  id="sidebar"
  class="sidebar-min"
  [ngClass]="{ 'page-wrapper__sidebar__show': !getSideBarState() }">
  <div class="sidebar-content">
    <perfect-scrollbar>
      <div class="sidebar-header">
        <h2></h2>
      </div>
      <div class="sidebar-header-version">
        <span class="version-info">
          <strong>ver.1.0</strong>
        </span>
      </div>
      <div class="sidebar-menu">
        <ul>
          <li
            *ngFor="let menu of menus"
            [ngClass]="{
              active: menu.active,
              'sidebar-dropdown': menu.type === 'dropdown',
              'header-menu': menu.type === 'header'
            }">
            <span *ngIf="menu.type === 'header'">{{ menu.title }}</span>

            <a
              *ngIf="
                menu.type !== 'header' &&
                menu.type !== 'dropdown' &&
                !menu.authority.includes(getBlockedAuthType())
              "
              (click)="toggle(menu)"
              routerLink="{{ menu.url }}">
              <i class="{{ menu.icon }}" aria-hidden="true"></i>
              <span
                *ngIf="menu.badge"
                class="badge badge-pill"
                [ngClass]="menu.badge.class"
                >{{ menu.badge.text }}</span
              >
            </a>
            <a
              *ngIf="
                menu.type === 'dropdown' &&
                !menu.authority.includes(getBlockedAuthType())
              "
              (click)="toggle(menu)">
              <i class="{{ menu.icon }}" aria-hidden="true"></i>
              <span
                *ngIf="menu.badge"
                class="badge badge-pill"
                [ngClass]="menu.badge.class"
                >{{ menu.badge.text }}</span
              >
            </a>
            <div
              *ngIf="
                menu.type === 'dropdown' &&
                !menu.authority.includes(getBlockedAuthType())
              "
              class="sidebar-submenu"
              [@slide]="getState(menu)">
              <ul>
                <li *ngFor="let submenu of menu.submenus">
                  <a
                    *ngIf="!submenu.authority.includes(getBlockedAuthType())"
                    routerLink="{{ submenu.url }}">
                    {{ submenu.title }}
                    <span
                      *ngIf="submenu.badge"
                      class="badge badge-pill"
                      [ngClass]="submenu.badge.class"
                      >{{ submenu.badge.text }}</span
                    >
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div
        class="sidebar-arrow"
        [ngClass]="{ siderbar__conunts__hidden: getSideBarState() }"
        (click)="toggleSidebar()">
        <i class="fa fa-angle-double-right" aria-hidden="true"></i>
      </div>
    </perfect-scrollbar>
  </div>
</nav>
<header class="p__header">
  <div>
    <button mat-button [matMenuTriggerFor]="menu1" (mouseenter)="openMyMenu()">
      <u style="color: #fff">{{ userName }}</u>
    </button>
    <mat-menu #menu1="matMenu" overlapTrigger="false">
      <span (mouseleave)="closeMyMenu()">
        <button mat-menu-item (click)="onLogout()">ログアウト</button>
      </span>
    </mat-menu>
  </div>
  <div>
    <span id="" class="btn" data-toggle="modal" style="color: #fff">
      ユーザー権限：{{ userAuthorityName }}
    </span>
  </div>
  <div>
    <span id="" class="btn" data-toggle="modal" style="color: #fff">
      組織名：{{ orgName }}
    </span>
  </div>
</header>
<section
  class="main-contents page-content siderbar__conunts"
  [ngClass]="{ siderbar__conunts__hidden: getSideBarState() }">
  <router-outlet></router-outlet>
</section>
