<!-- <h1 mat-dialog-title>{{ data.title }}</h1> -->
<h1 mat-dialog-title></h1>
<div mat-dialog-content style="text-align: center">
  <p>{{ data.message }}</p>
</div>
<div
  *ngIf="!data.labelOk"
  mat-dialog-actions
  style="text-align: center; margin-bottom: 0px">
  <button
    type="button"
    mat-button
    class="c-button__primary"
    style="margin-right: 6%; background: #027e6a; font-weight: bolder"
    [mat-dialog-close]="true"
    cdkFocusInitial>
    {{ data.labelOk ? data.labelOk : 'はい' }}
  </button>
  <button
    type="button"
    mat-button
    class="c-button__primary"
    style="margin-right: 20%; background: #027e6a; font-weight: bolder"
    [mat-dialog-close]="false">
    {{ data.labelCancel ? data.labelCancel : 'いいえ' }}
  </button>
</div>
<div
  *ngIf="data.labelOk"
  mat-dialog-actions
  style="text-align: center; margin-bottom: 0px">
  <button
    type="button"
    mat-button
    class="c-button__primary"
    style="margin-right: 6%; background: #027e6a; font-weight: bolder"
    [mat-dialog-close]="true"
    cdkFocusInitial>
    {{ data.labelOk ? data.labelOk : 'はい' }}
  </button>
  <button
    type="button"
    mat-button
    class="c-button__primary"
    style="margin-right: 10%; background: #027e6a; font-weight: bolder"
    [mat-dialog-close]="false">
    {{ data.labelCancel ? data.labelCancel : 'いいえ' }}
  </button>
</div>
