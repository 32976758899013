import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProgressManagerService {
  private progressStore = new BehaviorSubject(false);
  private progressCount = 0;

  constructor() {}

  processing(): void {
    this.progressCount++;
    if (this.progressCount === 1) {
      this.progressStore.next(true);
    }
  }

  done(): void {
    if (this.progressCount > 0) {
      this.progressCount--;
    }
    if (this.progressCount === 0) {
      this.progressStore.next(false);
    }
  }

  reset(): void {
    if (this.progressCount !== 0) {
      this.progressCount = 0;
    }
    if (this.progressStore.getValue()) {
      this.progressStore.next(false);
    }
  }

  handler(): Observable<boolean> {
    return this.progressStore.asObservable();
  }
}
