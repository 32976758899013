import { Injectable } from '@angular/core';
import { BehaviorSubject, from, switchMap } from 'rxjs';
import { ProgressManagerService } from './progress-manager.service';
import { AmplifyApiService } from './amplify-api.service';
import { tap, share, filter, map } from 'rxjs/operators';
import Auth from '@aws-amplify/auth';

@Injectable({
  providedIn: 'root',
})
export class AppsApiService {
  private resultSubject = new BehaviorSubject(undefined);

  constructor(
    private progressManagerService: ProgressManagerService,
    private amplifyApiService: AmplifyApiService
  ) {}

  getAppsList(req) {
    this.progressManagerService.processing();

    return from(
      Auth.currentSession()
        .then(data => data.getIdToken().getJwtToken())
        .catch(err => {
          this.progressManagerService.done();
          return '';
        })
    ).pipe(
      filter(token => !!token),
      switchMap(token => {
        return this.amplifyApiService
          .get('/admin/applications', token, req)
          .pipe(
            tap(res => {
              this.progressManagerService.done();
            }),
            share(),
            map(result => {
              return result;
            }),
            tap(result => this.resultSubject.next(result))
          );
      })
    );
  }

  getOrgInfo(req) {
    this.progressManagerService.processing();

    return from(
      Auth.currentSession()
        .then(data => data.getIdToken().getJwtToken())
        .catch(err => {
          this.progressManagerService.done();
          return '';
        })
    ).pipe(
      filter(token => !!token),
      switchMap(token => {
        return this.amplifyApiService
          .post('/admin/organizations/search', token, req)
          .pipe(
            tap(res => {
              this.progressManagerService.done();
            }),
            share(),
            map(result => {
              return result;
            }),
            tap(result => this.resultSubject.next(result))
          );
      })
    );
  }

  getAuthorizer(req) {
    this.progressManagerService.processing();

    return from(
      Auth.currentSession()
        .then(data => data.getIdToken().getJwtToken())
        .catch(err => {
          this.progressManagerService.done();
          return '';
        })
    ).pipe(
      filter(token => !!token),
      switchMap(token => {
        return this.amplifyApiService
          .post('/admin/users/search', token, req)
          .pipe(
            tap(res => {
              this.progressManagerService.done();
            }),
            share(),
            map(result => {
              return result;
            }),
            tap(result => this.resultSubject.next(result))
          );
      })
    );
  }

  addEditDelUsers(req) {
    this.progressManagerService.processing();

    return from(
      Auth.currentSession()
        .then(data => data.getIdToken().getJwtToken())
        .catch(err => {
          this.progressManagerService.done();
          return '';
        })
    ).pipe(
      filter(token => !!token),
      switchMap(token => {
        return this.amplifyApiService.post('/admin/users', token, req).pipe(
          tap(res => {
            this.progressManagerService.done();
          }),
          share(),
          map(result => {
            return result;
          }),
          tap(result => this.resultSubject.next(result))
        );
      })
    );
  }
}
