import { Injectable } from '@angular/core';

/**
 * アクセストークンを保持・管理する
 */
@Injectable({
  providedIn: 'root'
})
export class AccessTokenService {
  readonly MIRAMED_ACCESS_TOKEN = 'fhdsajgpeir';

  constructor() {}

  saveToken(token: string) {
    localStorage.setItem(this.MIRAMED_ACCESS_TOKEN, token);
  }
  getToken() {
    return localStorage.getItem(this.MIRAMED_ACCESS_TOKEN);
  }
  clearToken() {
    localStorage.removeItem(this.MIRAMED_ACCESS_TOKEN);
  }
}
