export default class Msg {
  private msg: { [key: string]: string };

  constructor() {
    this.msg = {
      E0001: '必須入力です。',
      E0002: '{0}の場合、必須入力です。',
      E0003: '{0}で入力してください。',
      E0004: '{0}の登録可能なファイルは{1}です。',
      E0005: '選択可能な{0}は最大{1}までです。',
      E0006: '日付範囲は{0}≦{1}となるように指定してください',
      E0007: '申込IDに誤りがあります。一覧で選択した申込IDと一致しません。',
      E0008: '同意チェックにチェックしてください。',
      E0009: '{0}のその他を選択する場合、{1}が必須入力です。',
      E0010: '利用可能な半角英数記号で入力してください',
      E0011: '電子メールのフォーマットは正しくありません',
      E0012: '電話番号のフォーマットは正しくありません',
      E0013: '{0}、{1}はどちらか一方しか選択できません。',
      E0014: '{0}は、{1}で登録してください。',
      E0015: '{0}で入力してください。/Please enter in {1}.',
      E0016: '必須入力です。/required.',
      E0017: 'アップロードするファイルサイズの上限は50Mです。',
      I0013: 'ワンストップサービスの利用権限がありません。\nログアウトします。',
      I0014: '準備中の商品になります。',
      I0015: 'IDトークンの有効期限が切れました。',
    };
  }

  // メッセージIDよりメッセージ内容を取得する
  public getMsg(id: string): string {
    return this.msg[id];
  }
}
