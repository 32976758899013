export const environment = {
  production: true,
  apiName: 'oneStopapiv1',
  secretariatMail: 'business@miraikiko.jp', //機構事務局のメールアドレス
  inquiryFormUrl:'https://portal.nakanoshima-qross.jp/%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B/',
  applicationUrl:'https://portal.nakanoshima-qross.jp/%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B/',//法人会員のお申込のURL
  apiUrl: 'https://6ha3pxrz31.execute-api.ap-northeast-1.amazonaws.com/production',
  apiKey: 'pofN5VxnzT4AYDX28AbAt6pJITmhVKnL1qQ154wt',
  aws_accessKeyId: 'AKIASPCF4J25MEEEDX4C',
  aws_secretAccessKey: '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',
  region: 'ap-northeast-1',
  identityPoolId: 'ap-northeast-1:f248152b-d993-4577-80b6-6022b7bed4c7',
  userPoolId: 'ap-northeast-1_RkOSxsDSu',
  clientId: '139h24rfp2jbg1fkkn51ov2bq7',
  bucketName: '',
  cognito_idp_endpoint: '',
  cognito_identity_endpoint: '',
  sts_endpoint: '',
  dynamodb_endpoint: '',
  s3_endpoint: 'onestop-develop-file',
  template_url: 'https://phr-files.kenkohshien-test.com',
  authed: false,
  authorityType: 0,
  login_kbn: 0,
  devMode: '0',
  showMenu: true,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
