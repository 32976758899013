<nav
  *ngIf="!data.dialogFlg"
  class="navbar bg-body-tertiary"
  style="margin-right: 15px">
  <div class="container-fluid">
    <span class="navbar-brand mb-0 h1">{{ data.service_name }}</span>
  </div>
</nav>
<nav *ngIf="data.dialogFlg" class="navbar bg-body-tertiary">
  <div class="container-fluid">
    <span class="navbar-brand mb-0 h1">{{ data.service_name }}</span>
  </div>
</nav>
<br />
<div style="display: flex">
  <div style="width: 330px; flex-shrink: 0">
    <img
      style="width: 330px"
      [src]="data.service_picture_save_path"
      *ngIf="
        data.service_picture_save_path;
        else elseImg
      " />
    <ng-template #elseImg>
      <img [src]="serverImgPaath" style="width: 330px" />
    </ng-template>
  </div>
  <span
    style="
      padding: 20px;

      word-wrap: break-word;
      white-space: pre-wrap;
      word-break: break-all;
    "
    >{{ data.service_explanation }}</span
  >
</div>
<div style="display: flex">
  <div style="width: 330px; flex-shrink: 0">
    <div *ngIf="!data.showFlg" style="width: 330px">
      <p style="margin: 0; margin-top: 20px">開催期間：</p>
      <p
        style="margin: 0; margin-top: 5px"
        *ngIf="data.event_day_begin && data.event_day_end">
        {{ data.event_day_begin }}～{{ data.event_day_end }}
      </p>
      <p
        style="margin: 0; margin-top: 5px"
        *ngIf="data.event_day_begin && !data.event_day_end">
        {{ data.event_day_begin }}～
      </p>
      <p
        style="margin: 0; margin-top: 5px"
        *ngIf="!data.event_day_begin && data.event_day_end">
        ～{{ data.event_day_end }}
      </p>
      <p
        style="margin: 0; margin-top: 5px"
        *ngIf="!data.event_day_begin && !data.event_day_end">
        期間指定なし
      </p>
    </div>
  </div>
  <div style="padding: 20px">
    <span style="padding-top: 15px">■基本情報</span>
    <div
      style="
        margin-left: 15px;
        word-wrap: break-word;
        white-space: pre-wrap;
        word-break: break-all;
      ">
      {{ data.base_information }}
    </div>
    <span
      *ngIf="data.company_name"
      style="
        padding-top: 10px;
        margin-top: 15px;
        margin-bottom: 10px;
        display: block;
      "
      >■サービス提供事業者情報</span
    >
    <div style="margin-left: 15px; display: flex" *ngIf="data.company_name">
      <div>
        <div>
          <img
            style="width: 100px"
            [src]="
              data.company_logo_save_path
            "
            *ngIf="
              data.company_logo_save_path;
              else companyImg
            " />
          <ng-template #companyImg>
            <img [src]="companyDefaultImgPaath" style="width: 100px" />
          </ng-template>
        </div>
      </div>
      <div>
        <div style="margin-left: 15px">
          <p style="margin: 0; margin-bottom: 5px">{{ data.company_name }}</p>
          <p style="margin: 0">{{ data.business_description }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="data.dialogFlg" style="margin-top: 50px; width: 1100px">
  <div style="display: flex; justify-content: center; align-items: center">
    <button
      type="button"
      mat-button
      class="btnStyle"
      [mat-dialog-close]="true"
      cdkFocusInitial>
      閉じる
    </button>
  </div>
</div>
