<h1 mat-dialog-title style="text-align: center">{{ data.title }}</h1>
<div mat-dialog-content>
  <p style="text-align: center">{{ data.message }}</p>
</div>
<div mat-dialog-actions>
  <button
    type="button"
    mat-button
    class="btnStyle"
    [mat-dialog-close]="true"
    cdkFocusInitial>
    OK
  </button>
</div>
