import { Injectable } from '@angular/core';
import { API, Auth } from 'aws-amplify';
import {
  Subject,
  from,
  Observable,
  BehaviorSubject,
  tap,
  share,
  map,
  filter,
  switchMap,
  EMPTY,
} from 'rxjs';
import {
  MessageDialogComponent,
  MessageDialogData,
} from '../modules/shared/message-dialog/message-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ProgressManagerService } from './progress-manager.service';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, ObservableInput, of } from 'rxjs';
import { Router } from '@angular/router';
import { formatMsg } from 'src/app/utils/common';
import { ModalService } from './modal.service';
const { CognitoJwtVerifier } = require('aws-jwt-verify');
const { decomposeUnverifiedJwt } = require('aws-jwt-verify/jwt');
const xApiKey = environment.apiKey;
const apiUrl = environment.apiUrl;

const apiName = 'oneStopapiv1';
@Injectable({
  providedIn: 'root',
})
export class AmplifyApiService {
  messageArray = [];
  message = '';
  public errMsg = new Subject<string>();

  constructor(
    private dialog: MatDialog,
    private http: HttpClient,
    private progressManagerService: ProgressManagerService,
    private router: Router,
    private modalService: ModalService
  ) {}

  get<T>(apiPath: string, token: string, jsonData: any): Observable<T> {
    // SSOの場合はIDトークンをユーザー属性から取得する。
    return from(this.checkToken()).pipe(
      share(),
      switchMap(resultCheck => {
        console.log('---get---result', resultCheck);
        if (!resultCheck) {
          this.doLogout();
          return EMPTY;
        } else {
          this.progressManagerService.processing();
          const idToken = API.Auth['user']?.attributes?.sso_token || token;
          return from(
            API.get(apiName, apiPath, {
              headers: {
                'X-ID-TOKEN': `${idToken}`,
                'X-API-KEY': xApiKey,
              },
              //queryStringParameters: jsonData,
              queryStringParameters: {
                ...jsonData.param,
              },
            })
              .then(response => {
                this.progressManagerService.done();
                return response;
              })
              .catch(error => {
                if (idToken) {
                  this.editErrorMessage(error?.response);
                }
                this.progressManagerService.done();
              })
          );
        }
      })
    );
  }

  // processingを非表示
  getWithoutProcessing<T>(
    apiPath: string,
    token: string,
    jsonData: any
  ): Observable<T> {
    // エラーメッセージクリア
    this.errMsg.next('');
    return from(this.checkToken()).pipe(
      share(),
      switchMap(resultCheck => {
        console.log('---getWithoutProcessing---result', resultCheck);
        if (!resultCheck) {
          this.doLogout();
          return EMPTY;
        } else {
          this.progressManagerService.processing();
          // SSOの場合はIDトークンをユーザー属性から取得する。
          const idToken = API.Auth['user']?.attributes?.sso_token || token;
          // AWS AmplifyでAPIを呼び出す
          return from(
            API.get(apiName, apiPath, {
              headers: {
                'X-ID-TOKEN': `${idToken}`,
                'X-API-KEY': xApiKey,
              },
              queryStringParameters: jsonData,
            })
              .then(response => {
                return response;
              })
              .catch(error => {
                if (idToken) {
                  this.editErrorMessage(error?.response);
                }
              })
          );
        }
      })
    );
  }

  postWithAuth<T>(
    resultSubject: BehaviorSubject<T>,
    apiPath: string,
    jsonData: any
  ): Observable<T> {
    // エラーメッセージクリア
    this.errMsg.next('');

    if (environment.devMode === '1') {
      return this.postWithoutTokenByHttpClient(
        resultSubject,
        apiPath,
        jsonData
      );
    } else {
      return from(
        Auth.currentSession()
          .then(data => data.getIdToken().getJwtToken())
          .catch(err => {
            console.log('---currentSession err:', err);
            this.progressManagerService.done();
            return EMPTY;
          })
      ).pipe(
        filter(token => !!token),
        switchMap(token => {
          // SSOの場合はIDトークンをユーザー属性から取得する。
          return from(this.checkToken()).pipe(
            share(),
            switchMap(resultCheck => {
              console.log('resultCheck', resultCheck);
              if (!resultCheck) {
                this.doLogout();
                return EMPTY;
              } else {
                const idToken =
                  API.Auth['user']?.attributes?.sso_token || token;
                return this.post<T>(apiPath, idToken, jsonData).pipe(
                  tap(res => {
                    this.progressManagerService.done();
                  }),
                  share(),
                  map(result => {
                    return result as T;
                  }),
                  tap(result => resultSubject.next(result))
                );
              }
            })
          );
        })
      );
    }
  }

  postWithoutTokenByHttpClient<T>(
    resultSubject: BehaviorSubject<T>,
    apiPath: string,
    jsonData: any
  ): Observable<T> {
    // エラーメッセージクリア
    this.errMsg.next('');

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-API-KEY': xApiKey,
      }),
    };

    return this.http.post(apiUrl + apiPath, jsonData, httpOptions).pipe(
      tap(res => {
        this.progressManagerService.done();
      }),
      share(),
      map(result => {
        return result as T;
      }),
      tap(result => resultSubject.next(result)),
      catchError((error, caught): ObservableInput<any> => {
        this.progressManagerService.done();
        console.log(error);
        // qiu test
        if (error?.error.message) {
          this.errMsg.next(`APIエラー: ${error?.error.message}`);
        } else {
          this.errMsg.next(`APIエラー: ${error?.error.api_result?.message}`);
        }

        return of(null);
      })
    );
  }

  post<T>(apiPath: string, token: string, jsonData: any): Observable<T> {
    return from(this.checkToken()).pipe(
      share(),
      switchMap(resultCheck => {
        console.log('resultCheck', resultCheck);
        if (!resultCheck) {
          this.doLogout();
          return EMPTY;
        } else {
          this.progressManagerService.processing();
          const idToken = API.Auth['user']?.attributes?.sso_token || token;
          // AWS AmplifyでAPIを呼び出す
          return from(
            API.post(apiName, apiPath, {
              headers: {
                'X-ID-TOKEN': `${idToken}`,
                'X-API-KEY': xApiKey,
              },
              body: jsonData,
            })
              .then(response => {
                this.progressManagerService.done();
                return response;
              })
              .catch(error => {
                if (idToken) {
                  this.editErrorMessage(error?.response);
                }
                this.progressManagerService.done();
              })
          );
        }
      })
    );
  }

  postWithoutAuth<T>(
    resultSubject: BehaviorSubject<T>,
    apiPath: string,
    jsonData: any
  ): Observable<T> {
    // エラーメッセージクリア
    this.errMsg.next('');

    return this.postNoToken<T>(apiPath, jsonData).pipe(
      tap(res => {
        this.progressManagerService.done();
      }),
      share(),
      map(result => {
        return result as T;
      }),
      tap(result => resultSubject.next(result))
    );
  }

  postNoToken<T>(apiPath: string, jsonData: any): Observable<T> {
    this.progressManagerService.processing();
    // AWS AmplifyでAPIを呼び出す
    return from(
      API.post(apiName, apiPath, {
        headers: {
          'X-API-KEY': xApiKey,
        },
        body: jsonData,
      })
        .then(response => {
          this.progressManagerService.done();
          return response;
        })
        .catch(error => {
          this.editErrorMessage(error?.response);
          this.progressManagerService.done();
        })
    );
  }

  put<T>(apiPath: string, token: string, jsonData: any): Observable<T> {
    return from(this.checkToken()).pipe(
      share(),
      switchMap(resultCheck => {
        console.log('resultCheck', resultCheck);
        if (!resultCheck) {
          this.doLogout();
          return EMPTY;
        } else {
          this.progressManagerService.processing();
          // SSOの場合はIDトークンをユーザー属性から取得する。
          const idToken = API.Auth['user']?.attributes?.sso_token || token;
          // AWS AmplifyでAPIを呼び出す
          return from(
            API.put(apiName, apiPath, {
              headers: {
                'X-ID-TOKEN': `${idToken}`,
                'X-API-KEY': xApiKey,
              },
              body: jsonData,
            })
              .then(response => {
                this.progressManagerService.done();
                return response;
              })
              .catch(error => {
                if (idToken) {
                  this.editErrorMessage(error?.response);
                }
                this.progressManagerService.done();
              })
          );
        }
      })
    );
  }

  delete<T>(apiPath: string, token: string, jsonData: any): Observable<T> {
    return from(this.checkToken()).pipe(
      share(),
      switchMap(resultCheck => {
        console.log('resultCheck', resultCheck);
        if (!resultCheck) {
          this.doLogout();
          return EMPTY;
        } else {
          this.progressManagerService.processing();
          // SSOの場合はIDトークンをユーザー属性から取得する。
          const idToken = API.Auth['user']?.attributes?.sso_token || token;
          // AWS AmplifyでAPIを呼び出す
          return from(
            API.del(apiName, apiPath, {
              headers: {
                'X-ID-TOKEN': `${idToken}`,
                'X-API-KEY': xApiKey,
              },
              body: jsonData,
              // queryStringParameters: {
              //   ...jsonData.param,
              // },
            })
              .then(response => {
                this.progressManagerService.done();
                return response;
              })
              .catch(error => {
                if (idToken) {
                  this.editErrorMessage(error?.response);
                }
                this.progressManagerService.done();
              })
          );
        }
      })
    );
  }

  editErrorMessage(response: any): void {
    // 一つの画面で複数のエラーが発生する場合、一つ目のダイアログのみを表示する
    if (response?.data.api_result?.message == 'システムメンテナンス中') {
      this.router.navigate(['/users/maintenance']);
      return;
    }
    if (
      this.dialog.openDialogs.length === 0 ||
      (this.dialog.openDialogs.length === 1 &&
        this.dialog.openDialogs[0].id === 'approval-dialog')
    ) {
      if (response?.status === 400) {
        this.message = '';
        if (Array.isArray(response?.data.api_result?.message)) {
          this.messageArray = response?.data.api_result?.message;
          this.messageArray.forEach(item => {
            this.message = this.message + item + '\n';
          });
        } else {
          if (response?.data.api_result?.message.includes(',')) {
            const wordsArr = response?.data.api_result?.message.split(',');
            wordsArr.forEach(item => {
              this.message += item + '\n';
            });
          } else {
            this.message = response?.data.api_result?.message;
          }
        }
        // ダイアログ表示
        // this.showMessage('APIエラー', this.message);
        this.errMsg.next(`APIエラー: ${this.message}`);
      } else if (response?.status === 403) {
        // ダイアログ表示
        // this.showMessage('APIエラー', 'アクセスが拒否されました。');
        this.errMsg.next('APIエラー:アクセスが拒否されました。');
      } else if (response && 'status' in response) {
        // ダイアログ表示
        // this.showMessage(
        //   'APIエラー',
        //   response?.data.api_result?.api_result_message
        // );
        if (response.status === 502) {
          this.errMsg.next('APIエラー: 予期せぬエラーが発生しました。');
        } else {
          this.errMsg.next(`APIエラー: ${response?.data.api_result?.message}`);
        }
      } else {
        // ダイアログ表示
        // this.showMessage('APIエラー', '予期せぬエラーが発生しました。');
        this.errMsg.next('メッセージエリア');
      }
    }

    if (
      this.dialog.openDialogs.length === 0 ||
      (this.dialog.openDialogs.length === 1 &&
        this.dialog.openDialogs[0].id === 'properties')
    ) {
      if (response?.status === 400) {
        this.message = '';
        if (Array.isArray(response?.data.api_result?.message)) {
          this.messageArray = response?.data.api_result?.message;
          this.messageArray.forEach(item => {
            this.message = this.message + item + '\n';
          });
        } else {
          // this.message = response?.data.api_result?.message;
          if (response?.data.api_result?.message.includes(',')) {
            const wordsArr = response?.data.api_result?.message.split(',');
            wordsArr.forEach(item => {
              this.message += item + '\n';
            });
          } else {
            this.message = response?.data.api_result?.message;
          }
        }
        // ダイアログ表示
        // this.showMessage('APIエラー', this.message);
        this.errMsg.next(`APIエラー: ${this.message}`);
      } else if (response?.status === 403) {
        // ダイアログ表示
        // this.showMessage('APIエラー', 'アクセスが拒否されました。');
        this.errMsg.next('APIエラー:アクセスが拒否されました。');
      } else if (response && 'status' in response) {
        // ダイアログ表示
        // this.showMessage(
        //   'APIエラー',
        //   response?.data.api_result?.api_result_message
        // );
        if (response.status === 502) {
          this.errMsg.next('APIエラー: 予期せぬエラーが発生しました。');
        } else {
          this.errMsg.next(`APIエラー: ${response?.data.api_result?.message}`);
        }
      } else {
        // ダイアログ表示
        // this.showMessage('APIエラー', '予期せぬエラーが発生しました。');
        this.errMsg.next('メッセージエリア');
      }
    }

    console.log('add-property-dialog-openDialogs235', this.dialog.openDialogs);
    if (
      this.dialog.openDialogs.length === 1 ||
      (this.dialog.openDialogs.length === 2 &&
        this.dialog.openDialogs[1].id === 'add-property')
    ) {
      if (response?.status === 400) {
        this.message = '';
        if (Array.isArray(response?.data.api_result?.message)) {
          this.messageArray = response?.data.api_result?.message;
          this.messageArray.forEach(item => {
            this.message = this.message + item + '\n';
          });
        } else {
          //this.message = response?.data.api_result?.message;
          if (response?.data.api_result?.message.includes(',')) {
            const wordsArr = response?.data.api_result?.message.split(',');
            wordsArr.forEach(item => {
              this.message += item + '\n';
            });
          } else {
            this.message = response?.data.api_result?.message;
          }
        }
        // ダイアログ表示
        // this.showMessage('APIエラー', this.message);
        this.errMsg.next(`APIエラー: ${this.message}`);
      } else if (response?.status === 403) {
        // ダイアログ表示
        // this.showMessage('APIエラー', 'アクセスが拒否されました。');
        this.errMsg.next('APIエラー:アクセスが拒否されました。');
      } else if (response && 'status' in response) {
        // ダイアログ表示
        // this.showMessage(
        //   'APIエラー',
        //   response?.data.api_result?.api_result_message
        // );
        if (response.status === 502) {
          this.errMsg.next('APIエラー: 予期せぬエラーが発生しました。');
        } else {
          this.errMsg.next(`APIエラー: ${response?.data.api_result?.message}`);
        }
      } else {
        // ダイアログ表示
        // this.showMessage('APIエラー', '予期せぬエラーが発生しました。');
        this.errMsg.next('メッセージエリア');
      }
    }

    if (
      this.dialog.openDialogs.length === 1 ||
      (this.dialog.openDialogs.length === 2 &&
        this.dialog.openDialogs[1].id === 'edit-property')
    ) {
      if (response?.status === 400) {
        this.message = '';
        if (Array.isArray(response?.data.api_result?.message)) {
          this.messageArray = response?.data.api_result?.message;
          this.messageArray.forEach(item => {
            this.message = this.message + item + '\n';
          });
        } else {
          //this.message = response?.data.api_result?.message;
          if (response?.data.api_result?.message.includes(',')) {
            const wordsArr = response?.data.api_result?.message.split(',');
            wordsArr.forEach(item => {
              this.message += item + '\n';
            });
          } else {
            this.message = response?.data.api_result?.message;
          }
        }
        // ダイアログ表示
        // this.showMessage('APIエラー', this.message);
        this.errMsg.next(`APIエラー: ${this.message}`);
      } else if (response?.status === 403) {
        // ダイアログ表示
        // this.showMessage('APIエラー', 'アクセスが拒否されました。');
        this.errMsg.next('APIエラー:アクセスが拒否されました。');
      } else if (response && 'status' in response) {
        // ダイアログ表示
        // this.showMessage(
        //   'APIエラー',
        //   response?.data.api_result?.api_result_message
        // );
        if (response.status === 502) {
          this.errMsg.next('APIエラー: 予期せぬエラーが発生しました。');
        } else {
          this.errMsg.next(`APIエラー: ${response?.data.api_result?.message}`);
        }
      } else {
        // ダイアログ表示
        // this.showMessage('APIエラー', '予期せぬエラーが発生しました。');
        this.errMsg.next('メッセージエリア');
      }
    }
  }

  showMessage(title: string, message: string): Observable<undefined> {
    const dialogRef = this.dialog.open<
      MessageDialogComponent,
      MessageDialogData,
      undefined
    >(MessageDialogComponent, {
      data: {
        title,
        message,
      },
      disableClose: true,
    });
    return dialogRef.afterClosed();
  }

  async checkToken(): Promise<boolean> {
    if (API.Auth['user']?.attributes?.sso_token) {
      const sso_token = API.Auth['user']?.attributes?.sso_token;
      const { payload } = decomposeUnverifiedJwt(sso_token);
      console.log('sso_token', sso_token);
      console.log('payload', payload);
      const issURL = new URL(payload.iss);
      const userPoolId = issURL.pathname.split('/')[1];
      const verifier = CognitoJwtVerifier.create({
        userPoolId: userPoolId,
        tokenUse: payload.token_use,
        clientId: payload.aud,
      });
      try {
        await verifier.verify(sso_token);
        console.log('sso_token verify success');
        return true;
      } catch (error) {
        console.log('sso_token verify error', error);
        return false;
      }
    }
    return true;
  }

  public async doLogout() {
    // aws amplifyのsingoutを呼び出す。

    try {
      await Auth.signOut({ global: true });
    } catch (error) {
      await Auth.signOut();
    }
    environment.authorityType = null;
    environment.authed = false;
    environment.authorityType = 0;
    this.router.navigate(['/login'], {
      queryParams: { kbn: environment.login_kbn },
    });
  }
}
