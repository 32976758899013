import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ServicePanelData } from './service-panel.data';

/**
 * サービス情報コンポーネント
 */
@Component({
  selector: 'app-service-panel',
  templateUrl: './service-panel.component.html',
  styleUrls: ['./service-panel.component.scss'],
})
export class ServicePanelComponent implements OnInit, OnDestroy {
  /** TODO:APIが決まったら、サービス情報とバンディング */
  @Input() data: ServicePanelData = {};
  public serverImgPaath = '';
  public companyDefaultImgPaath = '';
  /**
   * コンストラクタ
   */
  constructor() {}

  /**
   * 初期処理
   */
  ngOnInit(): void {
    if (!this.data?.showFlg) {
      this.serverImgPaath = sessionStorage.getItem('concierge_serviceImage');
      this.companyDefaultImgPaath = sessionStorage.getItem(
        'concierge_companyLogo'
      );
    } else {
      this.serverImgPaath = sessionStorage.getItem('marutto_serviceImage');
      this.companyDefaultImgPaath = sessionStorage.getItem(
        'marutto_companyLogo'
      );
    }
  }

  /**
   * 破棄処理
   */
  ngOnDestroy(): void {}
}
