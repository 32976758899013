import { FormGroup } from '@angular/forms';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { I18n } from 'aws-amplify';
/* import AmplifySlotComponent  */
import { onAuthUIStateChange, AuthState } from '@aws-amplify/ui-components';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ModalService } from './services/modal.service';
import { ProgressManagerService } from './services/progress-manager.service';
import { SidebarService } from './sidebar/sidebar.service';
import { filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import Auth from '@aws-amplify/auth';
import { environment } from 'src/environments/environment';
// @ts-ignore
import vocabularies from './i18n/vocabularies';
import { UserInfoService } from './services/user-info.service';

import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import awsExports from '../aws-exports';
import { NativeDateAdapter, DateAdapter } from '@angular/material/core';

import { formatDate } from 'src/app/utils/common';

class MyDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    return formatDate(date, 'yyyy/MM/dd');
  }
}
const sign = require('jwt-encode');

I18n.putVocabularies(vocabularies);
I18n.setLanguage('jp');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [{ provide: DateAdapter, useClass: MyDateAdapter }],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  showUserResignResult: boolean = true;
  title = 'amplify-angular-app';
  private onDestroy$ = new Subject<void>();
  spinner;
  userType;
  termsUrlName;
  termsUrl;
  public createForm: FormGroup;
  authState: AuthState;
  public formFields = {
    signIn: {
      username: {
        labelHidden: false,
        placeholder: 'ユーザーIDを入力してください。',
        isRequired: true,
        label: 'ユーザーID*',
      },
      password: {
        labelHidden: false,
        placeholder: 'パスワードを入力してください。',
        isRequired: true,
        label: 'パスワード*',
      },
    },
  };

  constructor(
    private router: Router,
    private modalService: ModalService,
    private progressManagerService: ProgressManagerService,
    private routeInfo: ActivatedRoute,
    private userInfoService: UserInfoService,
    public sidebarservice: SidebarService
  ) {}

  async ngOnInit() {
    window.addEventListener(
      'message',
      async event => {
        console.log('---------success-----------');
        if (event.data && event.data.action === 'SetToken') {
          debugger;
          console.log('----------------------settoken----------------------');
          const dummyUserName = event.data.pm_lit3;
          await Auth.signIn(event.data.pm_lit3, event.data.ol_kit2);
          await Auth.currentSession();

          const idToken = new AmazonCognitoIdentity.CognitoIdToken({
            IdToken: event.data.idToken,
          });

          console.log(await Auth.currentSession());

          const clientId = awsExports['aws_user_pools_web_client_id'];
          const userName = idToken.payload['cognito:username'];

          // LocalStorageのCognito用のUserDataを置き換える。
          // 以降、Auth.user.attributesで参照することができる。
          // sso_token属性を追加しているので、この属性で直接サインインとSSOを区別できる。
          const userAttributesObject = Auth['user'].attributes;
          const ssoUserAttributesObject = {
            email: idToken.payload['email'],
            email_verified: idToken.payload['email_verified'],
            sub: idToken.payload['sub'],
            sso_token: idToken.getJwtToken(),
            sso_username: userName,
          };
          const margeUserAttributesObject = {
            ...userAttributesObject,
            ...ssoUserAttributesObject,
          };
          const margeUserAttributes = Object.entries(
            margeUserAttributesObject
          ).map(([Name, Value]) => ({ Name, Value }));
          const cognitoUserData = {
            UserAttributes: margeUserAttributes,
            Username: userName,
          };
          localStorage.setItem(
            `CognitoIdentityServiceProvider.${clientId}.${dummyUserName}.userData`,
            JSON.stringify(cognitoUserData)
          );

          await Auth.currentSession();
          window.location.replace(window.location.origin); // 履歴を削除する

          this.router.navigateByUrl('/top');
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          window.location.replace(window.location.origin); // 履歴を削除する
        }
      },
      false
    );
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        const url = (event as NavigationEnd).url;

        if (
          url.startsWith('/users/userResignResult') ||
          url.startsWith('/users/registerUser') ||
          url.startsWith('/users/registerResign')
        ) {
          this.showUserResignResult = true;
          return;
        }
        if (url.startsWith('/users/maintenance')) {
          return;
        }
        this.showUserResignResult = false;

        // ユーザー一覧からメニューで遷移した場合、保持している検索条件を破棄する
        if (!url.startsWith('/users')) {
          this.userInfoService.setSearchUsers('');
        }
        // ページ遷移によって中断されたAPIによるローディング表示をリセットする
        this.progressManagerService.reset();

        if (url.startsWith('/sso')) {
          debugger;
          // window.location.replace(window.location.origin); // 履歴を削除する
          const searchParams = new URLSearchParams(window.location.search);
          const sessionId = searchParams.get('session_id');
          window.opener?.postMessage(
            {
              action: 'Loaded',
              id: sessionId,
            },
            'https://portal.nakanoshima-qross.jp' // ドメインは指定したほうがよい WordPressのドメイン
          );
        }
      });

    this.progressManagerService.handler().subscribe(data => {
      // ExpressionChangedAfterItHasBeenCheckedErrorを回避するために
      // setTimeoutで非同期化しています。
      setTimeout(() => {
        this.spinner = data;
      });
    });

    onAuthUIStateChange((nextAuthState, authData) => {
      console.log('in onAuthUIStateChange');
      console.log('nextAuthState ', nextAuthState);
      console.log('nextAuthState ', authData);

      this.authState = nextAuthState;
    });
  }

  getAuthed(): boolean {
    return environment.authed;
  }
  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
  ngAfterViewInit(): void {}

  public onCreate() {}
}
