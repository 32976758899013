import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AmplifyApiService } from './amplify-api.service';
import { ProgressManagerService } from './progress-manager.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdminApiService {
  /** 結果処理用Subject */
  private resultSubject = new BehaviorSubject(undefined);

  /**
   * コンストラクタ
   */
  constructor(
    private amplifyApiService: AmplifyApiService,
    private progressManagerService: ProgressManagerService
  ) {}

  /**
   * 操作履歴取得
   */
  postAdminOperationhistorysearch(req) {
    this.progressManagerService.processing();
    return this.amplifyApiService
      .postWithAuth(this.resultSubject, '/admin/operationhistorysearch', req)
      .pipe(
        catchError((err: any): any => {
          return this.progressManagerService.done();
        })
      );
  }

  /**
   * コード取得
   */
  getAdminCode(req) {
    this.progressManagerService.processing();
    return this.amplifyApiService
      .postWithAuth(this.resultSubject, '/admin/onestop/code', req)
      .pipe(
        catchError((err: any): any => {
          return this.progressManagerService.done();
        })
      );
  }
}
