import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InputFieldModule } from './input-field/input-field.module';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { MaterialModule } from '../material/material.module';
import { KeysPipe } from './pipes/keys.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { ServicePanelComponent } from './service-panel/service-panel.component';
import { ServicePanelDialogComponent } from './service-panel/service-panel-dialog.component';

/**
 * 各機能で利用するコンポーネントやディレクティブをまとめたモジュール
 */
@NgModule({
  declarations: [
    MessageDialogComponent,
    ConfirmDialogComponent,
    KeysPipe,
    PhonePipe,
    ServicePanelComponent,
    ServicePanelDialogComponent,
  ],
  imports: [CommonModule, MaterialModule],
  exports: [
    InputFieldModule,
    MessageDialogComponent,
    KeysPipe,
    PhonePipe,
    ConfirmDialogComponent,
    ServicePanelComponent,
    ServicePanelDialogComponent,
  ],
  entryComponents: [
    MessageDialogComponent,
    ConfirmDialogComponent,
    ServicePanelDialogComponent,
  ],
})
export class SharedModule {}
