import {
  FormGroupDirective,
  NgForm,
  AbstractControl
} from '@angular/forms';

export function isShowFormError(
  control: AbstractControl | null,
  form: FormGroupDirective | NgForm
) {
  if (!control || !form) {
    return false;
  }
  return !control.valid && (control.dirty || control.touched || form.submitted);
}
