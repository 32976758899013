import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { isShowFormError } from 'src/app/utils/is-show-form-error';
import { AmplifyApiService } from 'src/app/services/amplify-api.service';
import { Me } from 'src/app/models/me';
import { ModalService } from 'src/app/services/modal.service';
import { UsersApiService } from 'src/app/services/users-api.service';
import { Subscription, filter } from 'rxjs';

/**
 * 会員退会実行結果コンポーネント
 */
@Component({
  selector: 'app-user-resign-result',
  templateUrl: './user-resign-result.component.html',
  styleUrls: ['./user-resign-result.component.scss'],
})
export class UserResignResultComponent implements OnInit {
  readonly isShowFormError = isShowFormError;
  registerForm: FormGroup;
  public errMessage = '';
  public result = '退会しました。';
  me: Me | undefined;
  private errMessageSubscription: Subscription;
  /**
   * コンストラクタ
   */
  constructor(
    private amplifyApiService: AmplifyApiService,
    private usersApiService: UsersApiService,
    private modal: ModalService
  ) {}

  /**
   * 初期処理
   */
  ngOnInit(): void {
    this.errMessageSubscription = this.amplifyApiService.errMsg.subscribe({
      next: res => {
        this.errMessage = res;
      },
    });
    const urlParams = new URLSearchParams(window.location.search);
    const resign_token = urlParams.get('resign_token');

    const req = {
      param: { resign_token: resign_token },
    };

    this.usersApiService.postUsersResign(req).subscribe(res => {
      if (this.errMessage ==='APIエラー: リンクの有効期限が切れています。') {
        this.showResult('リンクの有効期限が切れています。');
      } else if (this.errMessage ==='') {
        this.showResult(this.result);
      } else {
        window.close();
      }
    });
  }

  showResult(message: string) {
    // 会員退会実行
    this.modal
      .showMessage('会員退会実行結果:', message)
      .pipe(filter(ok => ok))
      .subscribe(() => {});
  }
}
