import { Component, OnInit, HostBinding, ViewEncapsulation } from '@angular/core';

/**
 * 入力項目フィールドのヒントテキスト欄をゆるくラップするコンポーネント
 */
@Component({
  selector: 'app-field-hint',
  templateUrl: './field-hint.component.html',
  styleUrls: ['./field-hint.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FieldHintComponent implements OnInit {

  /**
   * ベーススタイルの css に対応した class を付与する
   */
  @HostBinding('class') defaultClass = 'c-field-hint';

  constructor() { }

  ngOnInit() {
  }

}
