import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Amplify } from 'aws-amplify';
import aws_exports from './aws-exports';

const config = aws_exports;
config['aws_cognito_identity_pool_id'] = environment.identityPoolId;
config['aws_user_pools_id'] = environment.userPoolId;
config['aws_user_pools_web_client_id'] = environment.clientId;
Amplify.configure(config);
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
