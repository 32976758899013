<div *ngIf="showUserResignResult">
  <router-outlet>
    <div class="shadow" *ngIf="spinner">
      <mat-spinner class="spinner" color="warn"> </mat-spinner>
    </div>
  </router-outlet>
</div>

<div *ngIf="!showUserResignResult">
  <div class="authenticator-logo" *ngIf="!getAuthed()">
    <h1 style="color: #1660c8">ワンストップサービス</h1>
    <p>ログインしてください。</p>
    <p>{{ userType }}</p>
  </div>
  <amplify-authenticator [formFields]="formFields" [hideSignUp]="true">
    <ng-template amplifySlot="authenticated">
      <div>
        <router-outlet>
          <div class="shadow" *ngIf="spinner">
            <mat-spinner class="spinner" color="warn"> </mat-spinner>
          </div>
        </router-outlet>
      </div>
    </ng-template>
  </amplify-authenticator>
  <div class="user-url" *ngIf="!getAuthed()">
    <a [href]="termsUrl">{{ termsUrlName }}</a>
  </div>
</div>
