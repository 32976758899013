<header class="p__header">
  <div class="p__header__left">
    <button
      mat-button
      [routerLink]="!getShowMenu() ? null : ['/top']"
      style="margin-top: 3px">
      <img
        src="../../../assets/images/home_logo.png"
        style="width: 11rem; height: 2rem" />
    </button>
  </div>
  <div>
    <div class="p__header__left" *ngIf="getShowMenu()">
      <button
        #menuBtn1
        mat-button
        [matMenuTriggerFor]="showSubMenu ? menu1 : null"
        (mouseenter)="openMyMenu('menu1')"
        [routerLink]="showSubMenu ? null : ['/selection/list']"
        [queryParams]="{ isManager: 0, fromMenu: 1 }">
        NQセレクション<i
          class="fa fa-caret-down"
          style="font-size: x-large; margin-left: 2px"
          *ngIf="showSubMenu"></i>
      </button>
      <mat-menu #menu1="matMenu" overlapTrigger="false">
        <span (mouseleave)="closeMyMenu('menu1')">
          <button
            mat-menu-item
            [routerLink]="['/selection/list']"
            [queryParams]="{ isManager: 1, fromMenu: 1 }">
            資材実績一覧
          </button>
        </span>
      </mat-menu>
    </div>
    <div class="p__header__left" *ngIf="getShowMenu()">
      <button
        #menuBtn2
        mat-button
        [matMenuTriggerFor]="showSubMenu ? menu2 : null"
        (mouseenter)="openMyMenu('menu2')"
        [routerLink]="showSubMenu ? null : ['/concierge/listRecommendation']"
        [queryParams]="{ fromMenu: 1 }">
        NQコンシェルジュ<i
          class="fa fa-caret-down"
          style="font-size: x-large; margin-left: 2px"
          *ngIf="showSubMenu"></i>
      </button>
      <mat-menu #menu2="matMenu" overlapTrigger="false">
        <span (mouseleave)="closeMyMenu('menu2')">
          <button
            mat-menu-item
            [routerLink]="['/concierge/listApplication']"
            [queryParams]="{ fromMenu: 1 }">
            申込一覧
          </button>
          <button
            mat-menu-item
            [routerLink]="['/concierge/listContents']"
            [queryParams]="{ fromMenu: 1 }">
            コンテンツ一覧
          </button>
        </span>
      </mat-menu>
    </div>
    <div class="p__header__left" *ngIf="getShowMenu()">
      <button
        #menuBtn3
        mat-button
        [matMenuTriggerFor]="showSubMenu ? menu3 : null"
        (mouseenter)="openMyMenu('menu3')"
        [routerLink]="showSubMenu ? null : ['/marutto/listRecommendation']"
        [queryParams]="{ fromMenu: 1 }">
        NQまるっと<i
          class="fa fa-caret-down"
          style="font-size: x-large; margin-left: 2px"
          *ngIf="showSubMenu"></i>
      </button>
      <mat-menu #menu3="matMenu" overlapTrigger="false">
        <span (mouseleave)="closeMyMenu('menu3')">
          <button
            mat-menu-item
            [routerLink]="['/marutto/listApplication']"
            [queryParams]="{ fromMenu: 1 }">
            申込一覧
          </button>
          <button
            mat-menu-item
            [routerLink]="['/marutto/listContents']"
            [queryParams]="{ fromMenu: 1 }">
            コンテンツ一覧
          </button>
        </span>
      </mat-menu>
    </div>

    <div class="p__header__right" *ngIf="shouWAccent || getShowMenu()">
      <button
        #menuBtn4
        mat-button
        [matMenuTriggerFor]="menu4"
        (mouseenter)="openMyMenu('menu4')">
        <i class="fa fa-user" style="color: #fff; font-size: xx-large"></i>
      </button>
      <mat-menu #menu4="matMenu" overlapTrigger="false">
        <span (mouseleave)="closeMyMenu('menu4')">
          <button mat-menu-item (click)="onAccount()" *ngIf="getShowMenu()">
            アカウント情報
          </button>
          <button mat-menu-item (click)="onLogout()" *ngIf="shouWAccent">
            ログアウト
          </button>
        </span>
      </mat-menu>
    </div>

    <div class="p__header__right" *ngIf="getShowMenu()">
      <button
        *ngIf="!showSubMenu"
        mat-button
        [routerLink]="['/users/listApplication']"
        [queryParams]="{ fromMenu: 1 }">
        申込一覧
      </button>
    </div>
  </div>
</header>
<section class="main-contents page-content" style="margin-left: 15px">
  <router-outlet></router-outlet>
</section>
