//service
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserInfoService {
  constructor() {}

  setSearchDevice(value: string) {
    localStorage.setItem('searchDevice', value);
  }
  getSearchDevice() {
    return localStorage.getItem('searchDevice');
  }
  removeSearchDevice() {
    localStorage.removeItem('searchDevice');
  }

  setSearchOrganizations(value: string) {
    localStorage.setItem('searchOrganizations', value);
  }
  getSearchOrganizations() {
    return localStorage.getItem('searchOrganizations');
  }
  removeSearchOrganizations() {
    localStorage.removeItem('searchOrganizations');
  }

  setSearchCollection(value: string) {
    localStorage.setItem('searchCollection', value);
  }
  getSearchCollection() {
    return localStorage.getItem('searchCollection');
  }
  removeSearchCollection() {
    localStorage.removeItem('searchCollection');
  }

  setSearchProperty(value: string) {
    localStorage.setItem('searchProperty', value);
  }
  getSearchProperty() {
    return localStorage.getItem('searchProperty');
  }

  setSearchUsers(value: string) {
    localStorage.setItem('searchUsers', value);
  }
  getSearchUsers() {
    return localStorage.getItem('searchUsers');
  }
  removeSearchUsers() {
    localStorage.removeItem('searchUsers');
  }

  setSearchApprovals(value: string) {
    localStorage.setItem('searchApprovals', value);
  }
  getSearchApprovals() {
    return localStorage.getItem('searchApprovals');
  }

  setSearchApplications(value: string) {
    localStorage.setItem('searchApplications', value);
  }
  getSearchApplications() {
    return localStorage.getItem('searchApplications');
  }

  setCommonApps(value: string) {
    localStorage.setItem('commonApps', value);
  }
  getCommonApps() {
    return localStorage.getItem('commonApps');
  }

  setCommonCodes(value: string) {
    localStorage.setItem('commonCodes', value);
  }
  getCommonCodes() {
    return localStorage.getItem('commonCodes');
  }

  setCommonMails(value: string) {
    localStorage.setItem('commonMails', value);
  }
  getCommonMails() {
    return localStorage.getItem('commonMails');
  }

  setLoginInfo(value: string) {
    sessionStorage.setItem('loginInfo', value);
  }
  getLoginInfo() {
    return sessionStorage.getItem('loginInfo');
  }
}
