import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SearchInfoService {
  // 資材実績一覧
  searchName: string;
  searchId: string;
  searchFlg: number;

  // コンシェルジュ申込・問い合わせ一覧
  organizations_uid: string;
  organizations_name: string;
  concierge_classification_id: string;
  category_id: string;
  classification_id: string;
  application_from: string;
  application_to: string;
  status_kbn: string;
  applicationFlg: number;

  // コンテンツ一覧
  contents_classification_id: string;
  contents_category_id: string;
  event_day_begin: string;
  event_day_end: string;
  ConciergeContentsFlg: number;

  // まるっと申込一覧
  maruto_organizations_uid: string;
  maruto_service_kbn: string;
  maruto_classificationId: string;
  maruto_statusKbn: string;
  maruto_flg: number;

  // まるっとコンテンツ一覧
  serviceKbn: string;
  marutokotetsuFlg: number;

  // カテゴリートップ(コンシェルジュ)
  concierge_rem_event_day_begin: string;
  concierge_rem_event_day_end: string;

  constructor() {
    this.restoreFromSessionStorage();
  }

  restoreFromSessionStorage() {
    // 資材実績一覧
    this.searchName = sessionStorage.getItem('searchName') || '';
    this.searchId = sessionStorage.getItem('searchId') || '';
    this.searchFlg = Number(sessionStorage.getItem('searchFlg')) || 0;

    // コンシェルジュ申込・問い合わせ一覧
    this.organizations_uid = sessionStorage.getItem('organizations_uid') || '';
    this.organizations_name =
      sessionStorage.getItem('organizations_name') || '';
    this.concierge_classification_id =
      sessionStorage.getItem('concierge_classification_id') || '';
    this.category_id = sessionStorage.getItem('category_id') || '';
    this.classification_id = sessionStorage.getItem('classification_id') || '';
    this.application_from = sessionStorage.getItem('application_from') || '';
    this.application_to = sessionStorage.getItem('application_to') || '';
    this.status_kbn = sessionStorage.getItem('status_kbn') || '';
    this.applicationFlg = Number(sessionStorage.getItem('applicationFlg')) || 0;

    // コンテンツ一覧
    this.contents_classification_id =
      sessionStorage.getItem('contents_classification_id') || '';
    this.contents_category_id =
      sessionStorage.getItem('contents_category_id') || '';
    this.event_day_begin = sessionStorage.getItem('event_day_begin') || '';
    this.event_day_end = sessionStorage.getItem('event_day_end') || '';
    this.ConciergeContentsFlg =
      Number(sessionStorage.getItem('ConciergeContentsFlg')) || 0;

    // まるっと申込一覧
    this.maruto_organizations_uid =
      sessionStorage.getItem('maruto_organizations_uid') || '';
    this.maruto_service_kbn =
      sessionStorage.getItem('maruto_service_kbn') || '';
    this.maruto_classificationId =
      sessionStorage.getItem('maruto_classificationId') || '';
    this.maruto_statusKbn = sessionStorage.getItem('maruto_statusKbn') || '';
    this.maruto_flg = Number(sessionStorage.getItem('maruto_flg')) || 0;

    // まるっとコンテンツ一覧
    this.serviceKbn = sessionStorage.getItem('serviceKbn') || '';
    this.marutokotetsuFlg =
      Number(sessionStorage.getItem('marutokotetsuFlg')) || 0;

    // カテゴリートップ(コンシェルジュ)
    this.concierge_rem_event_day_begin =
      sessionStorage.getItem('concierge_rem_event_day_begin') || '';
    this.concierge_rem_event_day_end =
      sessionStorage.getItem('concierge_rem_event_day_end') || '';
  }

  /**
   * 変数の値をsessionStorageに保存する
   */
  saveToSessionStorage() {
    // 資材実績一覧
    sessionStorage.setItem('searchName', this.searchName);
    sessionStorage.setItem('searchId', this.searchId);
    sessionStorage.setItem('searchFlg', String(this.searchFlg));

    // コンシェルジュ申込・問い合わせ一覧
    sessionStorage.setItem('organizations_uid', this.organizations_uid);
    sessionStorage.setItem('organizations_name', this.organizations_name);
    sessionStorage.setItem(
      'concierge_classification_id',
      this.concierge_classification_id
    );
    sessionStorage.setItem('category_id', this.category_id);
    sessionStorage.setItem('classification_id', this.classification_id);
    sessionStorage.setItem('application_from', this.application_from);
    sessionStorage.setItem('application_to', this.application_to);
    sessionStorage.setItem('status_kbn', this.status_kbn);
    sessionStorage.setItem('applicationFlg', String(this.applicationFlg));

    // コンテンツ一覧
    sessionStorage.setItem(
      'contents_classification_id',
      this.contents_classification_id
    );
    sessionStorage.setItem('contents_category_id', this.contents_category_id);
    sessionStorage.setItem('event_day_begin', this.event_day_begin);
    sessionStorage.setItem('event_day_end', this.event_day_end);
    sessionStorage.setItem(
      'ConciergeContentsFlg',
      String(this.ConciergeContentsFlg)
    );

    // まるっと申込一覧
    sessionStorage.setItem(
      'maruto_organizations_uid',
      this.maruto_organizations_uid
    );
    sessionStorage.setItem('maruto_service_kbn', this.maruto_service_kbn);
    sessionStorage.setItem(
      'maruto_classificationId',
      this.maruto_classificationId
    );
    sessionStorage.setItem('maruto_statusKbn', this.maruto_statusKbn);
    sessionStorage.setItem('maruto_flg', String(this.maruto_flg));

    // まるっとコンテンツ一覧
    sessionStorage.setItem('serviceKbn', this.serviceKbn);
    sessionStorage.setItem('marutokotetsuFlg', String(this.marutokotetsuFlg));

    // カテゴリートップ(コンシェルジュ)
    sessionStorage.setItem(
      'concierge_rem_event_day_begin',
      this.concierge_rem_event_day_begin
    );
    sessionStorage.setItem(
      'concierge_rem_event_day_end',
      this.concierge_rem_event_day_end
    );
  }

  clear() {
    // 資材実績一覧
    this.searchName = '';
    this.searchId = '';
    this.searchFlg = 0;

    // コンシェルジュ申込・問い合わせ一覧
    this.organizations_uid = '';
    this.organizations_name = '';
    this.concierge_classification_id = '';
    this.category_id = '';
    this.classification_id = '';
    this.application_from = '';
    this.application_to = '';
    this.status_kbn = '';
    this.applicationFlg = 0;

    // コンテンツ一覧
    this.contents_classification_id = '';
    this.contents_category_id = '';
    this.event_day_begin = '';
    this.event_day_end = '';
    this.ConciergeContentsFlg = 0;

    // まるっと申込一覧
    this.maruto_organizations_uid = '';
    this.maruto_service_kbn = '';
    this.maruto_classificationId = '';
    this.maruto_statusKbn = '';
    this.maruto_flg = 0;

    // まるっとコンテンツ一覧
    this.serviceKbn = '';
    this.marutokotetsuFlg = 0;

    // カテゴリートップ(コンシェルジュ)
    this.concierge_rem_event_day_begin = '';
    this.concierge_rem_event_day_end = '';

    this.saveToSessionStorage();
  }
}
