import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, from, switchMap } from 'rxjs';
import { AccessTokenService } from './access-token.service';
import { ProgressManagerService } from './progress-manager.service';
import { AmplifyApiService } from './amplify-api.service';
import { tap, share, filter, map } from 'rxjs/operators';
import Auth from '@aws-amplify/auth';

@Injectable({
  providedIn: 'root',
})
export class AccountApiService {
  private accountSubject = new BehaviorSubject(undefined);
  private userAccountSubject = new BehaviorSubject(undefined);
  constructor(
    private http: HttpClient,
    private accessTokenService: AccessTokenService,
    private progressManagerService: ProgressManagerService,
    private amplifyApiService: AmplifyApiService
  ) {}
  me() {
    if (this.accountSubject.value) {
      return this.accountSubject
        .asObservable()
        .pipe(filter(result => result !== undefined))!;
    }
    this.progressManagerService.processing();

    return from(
      Auth.currentSession()
        .then(data => data.getIdToken().getJwtToken())
        .catch(err => {
          this.progressManagerService.done();
          return '';
        })
    ).pipe(
      filter(token => !!token),
      switchMap(token => {
        return this.amplifyApiService
          .post('/users/onestop/me', token, this.getUsersOnestopMeReq())
          .pipe(
            tap(res => {
              this.progressManagerService.done();
            }),
            share(),
            map((result: any) => {
              return result?.api_data[0];
            }),
            tap(result => this.accountSubject.next(result))
          );
      })
    );
  }

  async getUsersOnestopMeReq() {
    const currentUser = await Auth.currentAuthenticatedUser();
    const customUid = currentUser.attributes['custom:uid'];

    const req = {
      param: {
        user_uid: customUid,
      },
    };

    return req;
  }

  logout() {
    if (this.accountSubject.value) {
      this.accountSubject.next(undefined);
    }

    if (this.userAccountSubject.value) {
      this.userAccountSubject.next(undefined);
    }
  }
}
