import { Component, OnInit, HostBinding, ViewEncapsulation } from '@angular/core';

/**
 * 入力項目フィールドの項目ラベルをゆるくラップするコンポーネント
 */
@Component({
  selector: 'app-field-label',
  templateUrl: './field-label.component.html',
  styleUrls: ['./field-label.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FieldLabelComponent implements OnInit {

  /**
   * ベーススタイルの css に対応した class を付与する
   */
  @HostBinding('class') defaultClass = 'c-field-label';

  constructor() { }

  ngOnInit() {
  }

}
