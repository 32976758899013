export function getCodes(groupId) {
  const codeInfo = sessionStorage.getItem('codeInfo');

  if (!codeInfo) {
    return [];
  }

  const parsedCodeInfo = JSON.parse(codeInfo);
  return parsedCodeInfo.filter(item => item.group_id === groupId);
}

/**
 * codesの配列内に指定されたcodeが存在するかどうかをチェックする
 * @param codes
 * @param code
 * @returns
 */
export function includesCodes(codes: any[], code) {
  if (!codes || codes.length == 0) {
    return false;
  }

  // codesの配列内に指定されたcodeが存在するかどうかをチェックする
  return codes.some(x => x.code_uid === code);
}

/**
 * 組織名の配列内に指定されたcodeが存在するかどうかをチェックする
 * @param codes
 * @param code
 * @returns
 */
export function includesOrganizationsId(codes: any[], code) {
  if (!codes || codes.length == 0) {
    return false;
  }

  // codesの配列内に指定されたcodeが存在するかどうかをチェックする
  return codes.some(x => x.organizations_uid === code);
}

/**
 * 組織名リストを取得
 */
export function getOrganizationsNames() {
  const organizationsNames = sessionStorage.getItem('organizationsNames');

  if (!organizationsNames) {
    return [];
  }

  return JSON.parse(organizationsNames);
}

/**
 * ランダム関数を利用し、ランダムに10件表示する
 * (11件以上の場合、ランダム表示、10件までの場合、全件表示)
 */
export function getRandomArray(array, num) {
  let len = array.length;
  if (num >= len) return array;
  let result = new Array(num);
  let taken = new Array(len);
  for (let i = 0; i < num; i++) {
    let x = Math.floor(Math.random() * len);
    result[i] = array[x in taken ? taken[x] : x];
    taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
}

import { environment } from '../../environments/environment';

const dateFns = require('date-fns');

/**
 * date: 日付オブジェクト
 * format: 書式フォーマット
 * return 日付文字列
 **/
export function formatDate(date, format) {
  try {
    const jDate = date.toLocaleString('en-US', { timeZone: 'Asia/Tokyo' });
    const localDate = new Date(jDate);
    format = format.replace(/yyyy/g, localDate.getFullYear());
    format = format.replace(
      /MM/g,
      ('0' + (localDate.getMonth() + 1)).slice(-2)
    );
    format = format.replace(/dd/g, ('0' + localDate.getDate()).slice(-2));
    format = format.replace(/HH/g, ('0' + localDate.getHours()).slice(-2));
    format = format.replace(/mm/g, ('0' + localDate.getMinutes()).slice(-2));
    format = format.replace(/ss/g, ('0' + localDate.getSeconds()).slice(-2));
    format = format.replace(
      /SSS/g,
      ('00' + localDate.getMilliseconds()).slice(-3)
    );
    format = format.replace(/xxx/g, dateFns.format(localDate, 'xxx'));
  } catch (error) {
    console.debug(error);
    return undefined;
  }
  return format;
}

/**
 * dateFrom: 開始日付または日時
 * dateTo: 終了日付または日時
 * dateFrom > dateToの場合、trueを戻す
 * dateFrom < dateToの場合、falseを戻す
 **/
export function compareDate(dateFrom, dateTo) {
  try {
    const from = Date.parse(formatDate(dateFrom, 'yyyy/MM/dd'));
    const to = Date.parse(formatDate(dateTo, 'yyyy/MM/dd'));
    if (from > to) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.debug(error);
    return undefined;
  }
}

/**
 * strDate: 日付(yyyy/mm/dd)
 * Dateを戻す
 **/
export function string2date(strDate: string): Date | undefined {
  try {
    const stringDate =
      strDate.substring(0, 4) +
      '/' +
      strDate.substring(5, 7) +
      '/' +
      strDate.substring(8, 10) +
      ' 00:00:00';
    const date = new Date(
      new Date(stringDate).toLocaleString('en-US', { timeZone: 'Asia/Tokyo' })
    );
    return date;
  } catch (error) {
    console.debug(error);
    return undefined;
  }
}

/**
 * strDate: 日付(yyyymmdd)
 * Dateを戻す
 **/
export function string2date2(strDate: string): Date | undefined {
  try {
    const stringDate =
      strDate.substring(0, 4) +
      '/' +
      strDate.substring(4, 6) +
      '/' +
      strDate.substring(6, 8) +
      ' 00:00:00';
    const date = new Date(
      new Date(stringDate).toLocaleString('en-US', { timeZone: 'Asia/Tokyo' })
    );
    return date;
  } catch (error) {
    console.debug(error);
    return undefined;
  }
}

/**
 * phoneNumber: 電話番号形式チェック
 * format: 999-9999-9999
 * return:電話番号形式
 **/
export function validatePhoneNumberFormat(phoneNumber) {
  if (typeof phoneNumber === 'string') {
    phoneNumber = phoneNumber.replace(/\D/g, ''); // 数字保留
    if (phoneNumber.length === 11) {
      phoneNumber =
        phoneNumber.substring(0, 3) +
        '-' +
        phoneNumber.substring(3, 7) +
        '-' +
        phoneNumber.substring(7);
      return phoneNumber;
    }
  }
  return '';
}

import * as moment from 'moment';
import { FormGroup } from '@angular/forms';
export function formatDateForInput(form: FormGroup, event: Event) {
  // 入力要素を取得します
  const inputElement = event.target as HTMLInputElement;
  // 入力値を取得します
  const inputValue = inputElement.value;
  // 入力値を指定のフォーマットに変換します（YYYYMMDD → YYYY/MM/DD）
  const formattedValue = moment(inputValue, 'YYYYMMDD').format('YYYY/MM/DD');
  // 変換後の日付が有効かどうかチェックします
  if (moment(formattedValue).isValid()) {
    // 有効な場合、入力値を変更したフォーマットに更新します
    inputElement.value = formattedValue;
    // フォームコントロール名を取得します
    const formControlName = inputElement.getAttribute('formControlName');
    // フォームの該当するフォームコントロールに値を設定します
    form.get(formControlName).setValue(string2date(formattedValue));
  } else {
    // 無効な場合、該当するフォームコントロールの値をクリアします
    const formControlName = inputElement.getAttribute('formControlName');
    form.get(formControlName).setValue(null);
  }
}

/**
 * JSONオブジェクト内のnull値を空文字列に変換します。
 * @param {object} obj - 変換対象のJSONオブジェクト。
 * @returns {object} - 変換後のJSONオブジェクト。
 */
export function convertNullToEmptyString(obj) {
  // objのタイプがオブジェクトかどうかをチェックします
  if (typeof obj === 'object' && obj !== null) {
    // オブジェクトの各プロパティを反復処理します
    for (var key in obj) {
      // プロパティの値がnullかどうかをチェックします
      if (obj[key] === null) {
        // プロパティの値を空文字に変換します
        obj[key] = '';
      } else {
        // プロパティの値がオブジェクトの場合は、再帰的に関数を呼び出して処理します
        convertNullToEmptyString(obj[key]);
      }
    }
  }

  return obj;
}

import Msg from './msg';
const msgInstance = new Msg();

/*
   メッセージフォーマット
   msgId:メッセージID
   params:パラメーター配列
  */
export function formatMsg(msgId: string, params: string | any[]) {
  let result = msgInstance.getMsg(msgId);
  for (let i = 0; i < params.length; i++) {
    const index = '{' + i + '}';
    result = result.replace(index, params[i]);
  }
  return result;
}

/**
 * data: チェック対象データ
 * return true:値がある false:値がない
 **/
export function isset(data) {
  if (data !== undefined && data !== null && data !== '') {
    return true;
  } else {
    return false;
  }
}
