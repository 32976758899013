import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

export interface AgreementUserInfo {
  agree_status?: number;
  updated_at?: string;
  provider_name?: string;
  provider_uid?: string;
}

export interface UserAccountInfo {
  terms_url: string;
  uid?: string;
  company_name?: string;
  user_no?: string;
  sex?: string;
  family_name?: string;
  first_name?: string;
  family_name_kana?: string;
  first_name_kana?: string;
  date_of_birth?: string;
  phone_number?: string;
  mail_address?: string;
  insurance_number?: string;
  agreement_info_list?: Array<AgreementUserInfo>;
  terms_agreed?: number;
  terms_update_date?: string;
  appointed_provider?: string;
  connection_agreed?: boolean;
  connection_update_date?: string;
  agreement_updated_at?: string;
  created_at?: string;
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}
}
