import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServicePanelData } from './service-panel.data';
/**
 * サービス情報コンポーネント(モーダル)
 */
@Component({
  selector: 'app-service-panel-dialog',
  templateUrl: './service-panel.component.html',
  styleUrls: ['./service-panel.component.scss'],
})
export class ServicePanelDialogComponent implements OnInit, OnDestroy {
  public data: ServicePanelData = {};
  public serverImgPaath = '';
  public companyDefaultImgPaath = '';
  /**
   * コンストラクタ
   */

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: ServicePanelData) {
    this.data = dialogData['params'];
  }

  /**
   * 初期処理
   */
  ngOnInit(): void {
    if (!this.data?.showFlg) {
      this.serverImgPaath = sessionStorage.getItem('concierge_serviceImage');
      this.companyDefaultImgPaath = sessionStorage.getItem(
        'concierge_companyLogo'
      );
    } else {
      this.serverImgPaath = sessionStorage.getItem('marutto_serviceImage');
      this.companyDefaultImgPaath = sessionStorage.getItem(
        'marutto_companyLogo'
      );
    }
  }

  /**
   * 破棄処理
   */
  ngOnDestroy(): void {}
}
