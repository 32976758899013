<div style="margin-left: -15px; margin-right: 0px">
  <img
    src="../../../assets/images/top.png"
    style="width: 100%; max-height: calc(100vh - 43px); margin-top: 43px" />
</div>

<!-- <div class="c-page-header">
  <h2 class="c-page-header__title">ダッシュボード</h2>
</div> -->
<!--  
<form class="p-form">
  <h3>ダッシュボード画面です！</h3>
  <ul>
    <li><a [routerLink]="['/selection/list']">資材実績一覧</a></li>
    <li>
      <a [routerLink]="['/concierge/listApplication']"
        >コンシェルジュ申込・問合せ一覧</a
      >
    </li>
    <li>
      <a [routerLink]="['/concierge/listContents']"
        >コンシェルジュコンテンツ一覧</a
      >
    </li>
    <li><a [routerLink]="['/maruto/listApplication']">まるっと申込一覧</a></li>
    <li>
      <a [routerLink]="['/maruto/listContents']">まるっとコンテンツ一覧</a>
    </li>
    <li>
      <a [routerLink]="['/concierge/listService']">講座一覧</a>
    </li>
    <li>
      <a [routerLink]="['/maruto/listService']">まるっとサービス一覧</a>
    </li>
    <li>
      <a [routerLink]="['/users/listApplication']">申込一覧</a>
    </li>
    <li>
      <a [routerLink]="['/concierge/serviceUpdate']">お申込</a>
    </li>
    <li>
      <a [routerLink]="['/concierge/serviceInfo']" [queryParams]="{content_uid:'1'}">コンシェルジュ詳細</a>
    </li>
    <li>
      <a [routerLink]="['/maruto/contentsInfo']" [queryParams]="{service_uid:'1'}">まるっと詳細</a>
    </li>
    <li>
      <a [routerLink]="['/consultationinquiry/consultationinquiryUpdate']"
        >相談・お問合せ</a
      >
    </li>
    <li>
      <a [routerLink]="['/concierge/listRecommendation']"
        >中之島コンシェルジュサービス</a
      >
    </li>
    <li>
      <a [routerLink]="['/maruto/listRecommendation']"
        >中之島まるっとサービス
      </a>
    </li>
    <li>
      <a [routerLink]="['/users/registerUser']">会員登録 </a>
    </li>
    <li>
      <a [routerLink]="['/users/registerResign']">会員退会 </a>
    </li>
  </ul>
</form> -->
