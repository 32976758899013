import { NgModule } from '@angular/core';
import { FormFieldComponent } from './input-field/input-field.component';
import { FieldLabelComponent } from './field-label/field-label.component';
import { FieldHintComponent } from './field-hint/field-hint.component';

/**
 * フォームの入力項目カスタムエレメントを纏めたモジュール
 */
@NgModule({
  declarations: [FormFieldComponent, FieldLabelComponent, FieldHintComponent],
  exports: [FormFieldComponent, FieldLabelComponent, FieldHintComponent]
})
export class InputFieldModule {}
